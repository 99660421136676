/* eslint no-console:0 */
// Support component names relative to this directory:
import setupMainNavigation from 'common/mainNavigation';
import hamlPriceSwitch from 'common/priceSwitch';

const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

setupMainNavigation();
hamlPriceSwitch();
