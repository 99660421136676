import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@cityelectricalfactors/ui-components';
import {
  WhiteCheck,
  CrossIcon,
  DeleteIcon,
  EditIcon,
  ViewIcon,
} from 'components/shared/CommonSvgs';
import { formatDate } from 'helpers/utils';
import { useHTTP } from 'hooks/useRequest';
import {
  blackBlue85,
  successGreen,
} from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const SliderTable = ({ columns, data, className }) => {
  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: '',
    headers: {
      Accept: 'application/json',
    },
  });

  const { data: deleteData } = useHTTP(requestConfig);

  useEffect(() => {
    if (deleteData?.success) {
      window.location.reload();
    }
  }, [deleteData]);

  const handleDelete = id => {
    setRequestConfig({
      url: `/admin/sliders/${id}`,
      method: 'delete',
      headers: {
        Accept: 'application/json',
      },
    });
  };

  const renderCellContent = ({ field }, row) => {
    switch (field) {
      case 'start_date':
      case 'end_date':
        return <span>{formatDate(row[field])}</span>;
      case 'active':
        return row[field] ? (
          <WhiteCheck
            fillColor={successGreen}
            width="20"
            height="20"
            viewBox="0 0 10 7"
          />
        ) : (
          <CrossIcon
            fillColor={blackBlue85}
            width="20"
            height="20"
            viewBox="0 0 14 14"
          />
        );

      case 'options':
        return (
          <div className="responsive-table__actions">
            <DeleteSliderButton
              aria-label="Delete"
              handleClick={() => handleDelete(row.id)}
              className="m-0 p-0 ai-fs">
              <DeleteIcon />
            </DeleteSliderButton>
            <a aria-label="Edit" href={`/admin/sliders/${row.id}/edit`}>
              <EditIcon />
            </a>
            <a aria-label="View" href={`/admin/sliders/${row.id}`}>
              <ViewIcon />
            </a>
          </div>
        );
      default:
        return row[field];
    }
  };

  return (
    <ResponsiveTable className={`responsive-table mb-16 ${className}`}>
      <div className="responsive-table__header">
        {columns.map(({ field, header }) => (
          <div key={field} className="responsive-table__header-cell">
            {header}
          </div>
        ))}
      </div>
      <div className="responsive-table__body">
        {data.map(row => (
          <div key={row.id} className="responsive-table__row">
            {columns.map(col => (
              <div
                key={`${row.id}-${col.field}`}
                className={`responsive-table__cell ${
                  col.field === 'options'
                    ? 'responsive-table__cell--actions'
                    : ''
                }`}
                data-label={col.header}>
                {renderCellContent(col, row)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </ResponsiveTable>
  );
};

const ResponsiveTable = styled.div`
  .responsive-table__header,
  .responsive-table__row {
    /* Shared GTC for header row and columns. */
    grid-template-columns: 2fr 1fr 1fr 4fr 1fr 1fr 1fr 1fr;
  }
`;

const DeleteSliderButton = styled(Button)`
  align-items: flex-start;
  background: none;
  border: none;
  cursor: pointer;
`;

export default SliderTable;
