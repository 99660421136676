import React from 'react';
import { OutOfStockIcon } from 'components/shared/CommonSvgs';
import { orange10 } from '../../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const AddressLookupError = () => (
  <div className="fl ai-c p-6 fs-14 b-full b-colour--orange bgc---orange br-2 mb-16">
    <OutOfStockIcon
      className="mr-4"
      width="20"
      height="20"
      fillColor={orange10}
    />
    <p className="m-0">There was an error performing your search.</p>
  </div>
);

export default AddressLookupError;
