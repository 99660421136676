import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';

const useCookie = cookieName => {
  const [pricingCookieValue, setPricingCookieValue] = useState(
    () => Cookies.get(cookieName) || null,
  );

  const updateCookie = useCallback(
    (newValue, options) => {
      Cookies.set(cookieName, newValue, options);
      setPricingCookieValue(JSON.parse(newValue));
    },
    [cookieName],
  );

  const deleteCookie = useCallback(() => {
    Cookies.remove(cookieName);
    setPricingCookieValue(null);
  }, [cookieName]);

  return [pricingCookieValue, updateCookie, deleteCookie];
};

export { useCookie };
