import React, { useEffect } from 'react';

import Product from './product';

const ProductListings = ({
  addToFavourites,
  products,
  products_total: productsTotal,
  config,
  loading,
  isProductList,
  showVat,
  viewType,
}) => {
  const chunkProperties = (arr, n) => {
    return arr
      .slice(0, (arr.length + n - 1) / n || 0)
      .map((c, i) => arr.slice(n * i, n * i + n));
  };

  useEffect(() => {
    chunkProperties(products, 3);
  }, [products]);

  const addToFavouritesSet = productId => {
    addToFavourites(productId);
  };

  const allProducts = products || [];

  return (
    <div className="products-grid row" data-testid="product-list">
      {!loading && productsTotal === 0 && (
        <div className="col-12">
          Sorry, we were unable to find any products that match your
          requirements.
        </div>
      )}

      {productsTotal > 0 &&
        allProducts.map((product, index) => (
          <Product
            index={index}
            showVat={showVat}
            isProductList={isProductList}
            key={product.id}
            data={product}
            addToFavourites={() => addToFavouritesSet()}
            config={config}
            viewType={viewType}
          />
        ))}
    </div>
  );
};

export default ProductListings;
