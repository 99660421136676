import React from 'react';
import styled from 'styled-components';
import { Button } from '@cityelectricalfactors/ui-components';
import ProductStock from 'components/ProductStock';
import { AutocompleteCloseIcon } from './CommonSvgs';
import {
  grey70,
  successGreen,
} from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const ModalContentComponent = ({
  insufficientStock,
  stockLevel,
  quantity,
  productDescription,
  closeModal,
  isProductInBasket,
}) => (
  <>
    <div className="fl ai-c">
      <h2 className="fl ai-c fw-700 ff-rc m-0 fs-24 jc-c m-jc-fs plr-24 pt-18 pb-20">
        {insufficientStock ? 'Insufficient Stock' : 'Out Of Stock'}
      </h2>
      <CancelButton
        className="fl ai-c jc-c ml-auto mr-16"
        aria-label="Cancel"
        type="button"
        handleClick={closeModal}
        label="Cancel">
        <AutocompleteCloseIcon
          className="search-autocomplete__icon search-autocomplete__close"
          width="32"
          height="32"
          viewBox="0 0 20 20"
          fillColor={grey70}
        />
      </CancelButton>
    </div>

    <ModalContent className="fl plr-24">
      {insufficientStock ? (
        <>
          <ProductStock
            className="mb-10"
            stockLevel={stockLevel}
            size="medium"
          />
          <p className="fw-700 fs-16 mt-0">{productDescription}</p>
          <p className="m-0 fs-16">
            Your order will include these <BoldSpan>{stockLevel} </BoldSpan>
            items and the remaining{' '}
            <BoldSpan>
              {' '}
              {isProductInBasket
                ? isProductInBasket.quantity + quantity - stockLevel
                : quantity - stockLevel}{' '}
            </BoldSpan>{' '}
            items will be despatched once available.
          </p>
          <p>
            You&apos;ll receive a notification with an estimated delivery date.
          </p>
        </>
      ) : (
        <>
          <ProductStock
            className="mb-10"
            stockLevel={stockLevel}
            size="medium"
          />
          <p className="fw-700 fs-16 mt-0">{productDescription}</p>
          <p className="m-0 fs-16">
            This item is out of stock and can be placed on backorder. The item
            will be despatched once available.
          </p>
          <p>
            You&apos;ll receive a notification with an estimated delivery date.
          </p>
        </>
      )}
    </ModalContent>
  </>
);

const ModalContent = styled.div`
  flex-direction: column;
  margin: 0;
`;

const BoldSpan = styled.span`
  font-weight: 700;
  color: ${successGreen};
`;

const CancelButton = styled(Button)`
  background: none;
  font-weight: 500;
  border: 0;
  padding: 0;
  width: 40px;
  height: 40px;
`;

export default ModalContentComponent;
