/* eslint-disable max-len */
import React from 'react';

const GenericError = ({
  direction,
  errorTitle,
  errorMessage,
  isProductList,
}) => (
  <div
    className={`generic-error ${direction} ${isProductList && 'generic-error generic-error-product-list'}`}>
    {isProductList ? (
      <>
        <div className="svg-icon-error">
          <svg
            height="29.412"
            viewBox="0 0 31.69 29.412"
            width="31.69"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="m31.459 26.075-13.688-25.057a2.207 2.207 0 0 0 -3.846.226l-13.688 24.831c-.962 3 1.867 3.281 1.867 3.281h26.923a2.307 2.307 0 0 0 2.432-3.281zm-13.292-1.753a.6.6 0 0 1 -.6.6h-3.337a.6.6 0 0 1 -.6-.6v-3.3a.6.6 0 0 1 .6-.6h3.337a.6.6 0 0 1 .6.6zm.226-14.48c-.057.792-.283 7.749-.283 7.749s.045.509-.566.509h-3.393a.512.512 0 0 1 -.566-.566c0-.622-.226-7.862-.226-7.862a.618.618 0 0 1 .566-.566h3.9s.625-.056.568.736z"
              fill="#e25050"
              transform="translate(-.037)"
            />
          </svg>
        </div>
        <p>
          <strong>{errorTitle}</strong>
        </p>
      </>
    ) : (
      <>
        <div className="svg-icon-error">
          <svg
            height="29.412"
            viewBox="0 0 31.69 29.412"
            width="31.69"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="m31.459 26.075-13.688-25.057a2.207 2.207 0 0 0 -3.846.226l-13.688 24.831c-.962 3 1.867 3.281 1.867 3.281h26.923a2.307 2.307 0 0 0 2.432-3.281zm-13.292-1.753a.6.6 0 0 1 -.6.6h-3.337a.6.6 0 0 1 -.6-.6v-3.3a.6.6 0 0 1 .6-.6h3.337a.6.6 0 0 1 .6.6zm.226-14.48c-.057.792-.283 7.749-.283 7.749s.045.509-.566.509h-3.393a.512.512 0 0 1 -.566-.566c0-.622-.226-7.862-.226-7.862a.618.618 0 0 1 .566-.566h3.9s.625-.056.568.736z"
              fill="#e25050"
              transform="translate(-.037)"
            />
          </svg>
        </div>
        <div>
          <p>
            <strong>{errorTitle}</strong>
          </p>
          <p>{errorMessage}</p>
        </div>
      </>
    )}
  </div>
);

export { GenericError as default };
