import React, { useState, useEffect } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import useBasketStore from 'stores/basketStore/useBasketStore';
import { useHTTP } from 'hooks/useRequest';
import { useCookie } from 'hooks/useCookies';
import { createItemData } from 'utils/createItemData';
import BasketLines from 'components/Basket/BasketLines';
import OrderSummary from 'components/Checkout/OrderSummary';
import { FavouriteIcon, BasketErrorIcon } from 'components/shared/CommonSvgs';
import { orange10 } from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const Basket = ({
  basket: initialBasket,
  checkoutPath,
  whiteGoodsMinOrder,
}) => {
  const { clearBasket, setCurrentBasket, basket } = useBasketStore(state => ({
    basket: state.basket,
    clearBasket: state.clearBasket,
    setCurrentBasket: state.setCurrentBasket,
  }));

  const {
    white_goods_total: whiteGoodsTotal,
    contains_white_goods: containsWhiteGoods,
    lines,
  } = basket;
  const [pricingCookieValue] = useCookie('show_pricing');
  const parsedCookieValue = JSON.parse(pricingCookieValue);

  const [itemData, setItemData] = useState([]);

  useEffect(() => {
    setCurrentBasket(initialBasket);
  }, []);

  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: null,
    headers: {
      Accept: 'application/json',
    },
  });

  const { data } = useHTTP(requestConfig);

  const emptyBasket = () => {
    setRequestConfig({
      url: `/basket/clear_basket`,
      method: 'delete',
      headers: {
        Accept: 'application/json',
      },
    });
    clearBasket();
  };

  const storeBasket = () => {
    setRequestConfig({
      url: `/basket/save`,
      method: 'get',
      headers: {
        Accept: 'application/json',
      },
    });
    clearBasket();
  };

  useEffect(() => {
    const newItemData = basket?.lines?.map(
      (
        {
          product: {
            id,
            catalogue,
            description_short: description,
            brand,
            categories,
            rrp,
          },
          unit,
          quantity,
        },
        index,
      ) =>
        createItemData({
          id,
          catalogue,
          description,
          brand,
          categories,
          rrp,
          unit,
          quantity,
          index,
          itemListId: 'basket_page',
          itemListName: 'Basket Page',
        }),
    );

    setItemData(newItemData);
  }, [basket]);

  return (
    <>
      {whiteGoodsTotal > 0 && !data?.message && parsedCookieValue && (
        <div
          className={`basket-product__white-goods-message product-stock b-full b-colour--orange
            bgc--orange br-2 fl ai-c jc-fs p-l24 plr-16 pt-10 pb-10 mt-16
            ${whiteGoodsTotal > whiteGoodsMinOrder ? 'd-none' : ''}`}>
          <p className="fl ai-c m-0 fs-12 ">
            <BasketErrorIcon fillColor={orange10} className="mr-8" />
            <strong>
              You have spent &pound;{whiteGoodsTotal} on white goods items,
              which require premium delivery. You need to spend &pound;
              {whiteGoodsMinOrder} or more on these items to checkout.
            </strong>
          </p>
        </div>
      )}
      {data?.message && (
        <div className="row">
          <div
            className="col-12 m-col-9 mt-16 b-colour--green bgc--green
                      br-4 c--green ptb-8 plr-16">
            {data.message}
          </div>
        </div>
      )}
      {lines?.length ? (
        <div className="basket">
          <div className="basket-actions row mr-16 mb-16">
            <div className="fl m-gcs-8">
              <Button
                className="ff-body fs-14 c-black-blue bg-none width-mc"
                handleClick={() => emptyBasket()}>
                Empty Basket
              </Button>
            </div>
            <div className="fl">
              <Button
                className="fl ai-c ff-body fs-14 c-black-blue bg-none width-mc"
                handleClick={() => storeBasket()}>
                <FavouriteIcon
                  className="mr-4"
                  width={14}
                  height={14}
                  viewBox="0 0 20 20"
                />
                Save Basket
              </Button>
            </div>
          </div>

          <div className="row">
            <BasketLines
              basket={basket}
              whiteGoodsMinOrder={whiteGoodsMinOrder}
              containsWhiteGoods={containsWhiteGoods}
            />
            <OrderSummary
              itemData={itemData}
              disableCheckout={
                containsWhiteGoods !== null &&
                +whiteGoodsTotal < whiteGoodsMinOrder
              }
              checkoutPath={checkoutPath}
              currentBasket={basket}
              isBasketPage
            />
          </div>
        </div>
      ) : (
        <p>Your basket is currently empty.</p>
      )}
    </>
  );
};

export default Basket;
