import React from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import { GridViewIcon, SplitScreenIcon } from '../../shared/CommonSvgs';

const ProductView = ({ setViewType, currentViewType }) => {
  const ViewButton = ({ className, children, viewType }) => (
    <Button
      handleClick={() => setViewType(viewType)}
      className={`change-view__button fl ai-c p-10 bg-none b-full
      br-4 b-colour--grey ${className || ''}
      ${currentViewType === viewType && 'change-view__button--selected'}`}>
      {children}
    </Button>
  );

  return (
    <>
      <p className="products-options__sort-by-text ml-10 mr-6">View</p>
      <ViewButton className="mr-6" viewType="grid">
        <GridViewIcon />
      </ViewButton>
      <ViewButton viewType="list">
        <SplitScreenIcon />
      </ViewButton>
    </>
  );
};

export default ProductView;
