/* eslint-disable max-len */
import React from 'react';
import { Links } from 'common/accountSidebarLinks';
import AccountSidebarMobile from './AccountSidebarMobile';

const AccountSidebar = ({ currentPageName }) => (
  <>
    <AccountSidebarMobile links={Links} currentPageName={currentPageName} />
    <div className="account-sidebar fl fl-col mr-20">
      {Links.map(({ url, icon, text }, index, arr) => (
        <a
          key={text}
          href={url}
          className={`account-sidebar__link fl ai-c p-10 bt b-colour--grey td-none c--black-blue ${arr.length - 1 === index ? 'bb' : ''}`}>
          <div className="fl ai-c mr-5">{icon}</div>
          {text}
        </a>
      ))}
    </div>
  </>
);

export default AccountSidebar;
