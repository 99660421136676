import React from 'react';

class SpecialOfferContent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let savings_percentage = Number(
        (1 -
          Number(this.props.data.product.lowest_promotion_price.goods).toFixed(
            2,
          ) /
            Number(this.props.data.product.standard_price_goods).toFixed(2)) *
          100,
      ).toFixed(0),
      savingsTitle = 'SPECIAL OFFER';
    savings_percentage >= 10
      ? (savingsTitle = `A Saving of ${savings_percentage}%`)
      : 'SPECIAL OFFER';

    if (this.props.display_pricing == 'true') {
      var pricing = (
        <div className="product-body">
          <p className="product-text product-price product-old-price">
            Was £{Number(this.props.data.product.lowest_price.goods).toFixed(2)}
          </p>
          <p className="product-text product-price product-promo-price">
            Now £
            {Number(
              this.props.data.product.lowest_promotion_price.goods,
            ).toFixed(2)}
          </p>
        </div>
      );
    }

    return (
      <LimitedPod offer={this.props.offer}>
        <figure className="product-img">
          <a href={this.props.data.product.product_url}>
            <img
              className="img-full"
              src={this.props.data.product.medium_image_url}
            />
          </a>
        </figure>

        <header className="product-header">
          <h6 className="product-desc">
            <a href={this.props.data.product.product_url}>
              {this.props.data.product.description}
            </a>
          </h6>
        </header>

        {pricing}

        <h5 className="product-callout">{savingsTitle}</h5>
      </LimitedPod>
    );
  }
}

export default SpecialOfferContent;
