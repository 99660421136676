import React from 'react';

const AmountRow = ({ label, amount, isBold = false }) => (
  <div className={`row pl-16 pr-16 ${isBold ? 'pt-14 bt b-colour--grey' : ''}`}>
    <p className={`col-4 ${isBold ? 'fw-700 fs-16' : 'fs-14'}`}>{label}</p>
    <p className={`col-8 ta-r ${isBold ? 'fw-700 fs-16' : 'fs-14'}`}>
      &pound;{amount}
    </p>
  </div>
);

export default AmountRow;
