import React, { useState, useEffect } from 'react';
import useBasketStore from 'stores/basketStore/useBasketStore';

const SearchResult = ({
  setDeliveryAddressIsInvoiceAddress,
  id,
  address: {
    short_name: shortName,
    line_1: line1,
    line_2: line2,
    line_3: line3,
    line_4: line4,
    line_5: line5,
    postcode,
  },
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { setDeliveryAddress, selectedDeliveryAddress } = useBasketStore(
    state => ({
      setDeliveryAddress: state.setDeliveryAddress,
      selectedDeliveryAddress: state.selectedDeliveryAddress,
    }),
  );

  useEffect(() => {
    if (id === selectedDeliveryAddress?.id) {
      setIsChecked(true);
    }
  }, [selectedDeliveryAddress]);

  const setNewAddress = () => {
    setDeliveryAddressIsInvoiceAddress(false);
    const formattedAddress = {
      id,
      address_line_1: line1,
      address_line_2: line2,
      address_line_3: line3,
      address_line_4: line4,
      address_line_5: line5,
      address_postcode: postcode,
    };
    setDeliveryAddress(formattedAddress);
  };

  return (
    <div
      className="postcode-lookup__results col-12 col-lg-6 pap-grid-col
      pap-grid-col-animated checkout__address br-8 b-full
      b-colour-grey mb-5 fl ai-c jc-fs fs-14 ta-l">
      <div className="checkout__address-radio ">
        <label className="form-control p-16" htmlFor={`radio-${id}`}>
          <input
            id={`radio-${id}`}
            name="radio"
            type="radio"
            onChange={setNewAddress}
            checked={isChecked}
          />

          <span className="checkout__address-with-name m-0">{shortName}</span>
        </label>
      </div>
    </div>
  );
};

export default SearchResult;
