import React from 'react';

import LimitedPod from './timed.pod';
import LoadingAnimation from './loading.animation';

const SpecialLoadingState = props => (
  <LimitedPod offer={props.offer}>
    <LoadingAnimation />
  </LimitedPod>
);

export default SpecialLoadingState;
