import React from 'react';
import axios from 'packs/axios';
import Suggestion from './suggestion';
import AutoCompleteSectionHeader from './AutoCompleteSectionHeader';

const Suggestions = ({
  signedIn,
  results,
  numToShow,
  showCatalogue,
  showStockCode,
  showPrice,
  basketId,
  showDescriptionBrand,
  basketUrl,
  showVat,
  searchTerm,
}) => {
  const addToQuote = ({ preventDefault, target }, product) => {
    const btn = target;
    btn.setAttribute('disabled', true);

    preventDefault();

    axios({
      url: basketUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-TOKEN': document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content'),
      },
      data: {
        basket_line: {
          product_id: product.id,
          quantity: 1,
          basket_id: basketId,
        },
      },
    })
      .then(() => {
        PubSub.publish('BASKET UPDATE', 'Basket Updated');
        setTimeout(() => {
          btn.removeAttribute('disabled');
        }, 2000);
      })
      .catch(error => {
        PubSub.publish('add to basket error', error.response);
      });
  };

  return (
    <div className="suggestions">
      {results.length > 0 && <AutoCompleteSectionHeader content="Products" />}
      {results.slice(0, numToShow).map(pr => (
        <Suggestion
          stock={pr.stock}
          signedIn={signedIn}
          searchTerm={searchTerm}
          minimumOrder={pr.minimum_order}
          showVat={showVat}
          key={pr.id}
          url={pr.url ? pr.url : `/products/${pr.id}`}
          id={pr.id}
          stockCode={pr.stock_code}
          catalogue={pr.catalogue}
          thumbnail={pr.image_thumbnail ? pr.image_thumbnail : ''}
          brand={pr.brand}
          brandImage={pr.brand_image ? pr.brand_image : ''}
          description={pr.description}
          basketId={basketId}
          addToQuote={e => addToQuote(e, pr)}
          showCatalogue={showCatalogue}
          showStockCode={showStockCode}
          showPrice={showPrice}
          showDescriptionBrand={showDescriptionBrand}
        />
      ))}
    </div>
  );
};

export default Suggestions;
