const pushToDataLayer = (eventType, itemData, additionalData = {}) => {
  if (!window.dataLayer) return;

  // Clear the previous ecommerce object
  window.dataLayer.push({ ecommerce: null });

  let dataLayerEvent;

  switch (eventType) {
    // Product List view (search or category page)
    case 'view_item_list':
      dataLayerEvent = {
        event: 'view_item_list',
        ecommerce: {
          items: itemData,
        },
      };
      break;

    // Product list item click
    case 'select_item':
      dataLayerEvent = {
        event: 'select_item',
        ecommerce: {
          items: [itemData],
        },
      };
      break;

    // Product Detail page
    case 'view_item':
      dataLayerEvent = {
        event: 'view_item',
        ecommerce: {
          items: [itemData],
        },
      };
      break;

    case 'add_to_cart':
      dataLayerEvent = {
        event: 'add_to_cart',
        ecommerce: {
          add_to_cart_location: additionalData.add_to_cart_location || '',
          items: [itemData],
        },
      };
      break;

    case 'remove_from_cart':
      dataLayerEvent = {
        event: 'remove_from_cart',
        ecommerce: {
          items: itemData,
        },
      };
      break;

    case 'view_cart':
      dataLayerEvent = {
        event: 'view_cart',
        ecommerce: {
          currency: additionalData.currency || 'GBP',
          value: additionalData.value || 0.0,
          items: itemData,
        },
      };
      break;

    case 'begin_checkout':
      dataLayerEvent = {
        event: 'begin_checkout',
        ecommerce: {
          items: itemData,
        },
      };
      break;

    case 'add_address_info':
      dataLayerEvent = {
        event: 'add_address_info',
        ecommerce: {
          currency: additionalData.currency || 'GBP',
          value: additionalData.value || 0.0,
          address_option: additionalData.address_option || '',
          items: itemData,
        },
      };
      break;

    case 'add_shipping_info':
      dataLayerEvent = {
        event: 'add_shipping_info',
        ecommerce: {
          currency: additionalData.currency || 'GBP',
          value: additionalData.value || 0.0,
          address_option: additionalData.address_option || '',
          shipping_tier: additionalData.shipping_tier || '',
          items: itemData,
        },
      };
      break;

    case 'purchase':
      dataLayerEvent = {
        event: 'purchase',
        ecommerce: {
          affiliation: additionalData.affiliation || '',
          currency: additionalData.currency || 'GBP',
          value: additionalData.value || 0.0,
          tax: additionalData.tax || 0.0,
          shipping: additionalData.shipping || 0.0,
          address_option: additionalData.address_option || '',
          shipping_tier: additionalData.shipping_tier || '',
          payment_type: additionalData.payment_type || 'ACCOUNT',
          coupon: additionalData.coupon || '',
          items: itemData,
        },
      };
      break;

    default:
      return;
  }

  window.dataLayer.push(dataLayerEvent);
};

export { pushToDataLayer };
