import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button } from '@cityelectricalfactors/ui-components';
import AddressLookup from './deliveryAddressSearch/AddressLookup';

/**
 * @param {Object} props
 * @param {boolean} props.modalIsOpen - whether the modal is open or not
 * @param {function} props.closeModal - function to close modal
 * @param {function} props.successCallback - function to execute upon adding to basket
 * @returns modal with children
 */
const CheckoutNewAddressModal = ({
  setNewAddress,
  modalIsOpen,
  closeModal,
  setDeliveryAddressIsInvoiceAddress,
}) => {
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  return (
    <StyledModal isOpen={modalIsOpen} closeModal={closeModal}>
      <div>
        <ModalHeader className="fl ai-c fw-700 ff--rc jc-c m-jc-fs p-24">
          Add New Address
        </ModalHeader>

        <ModalContent className="fl p-24">
          <p>Please enter your postcode to find your location</p>
          <AddressLookup
            setDeliveryAddressIsInvoiceAddress={
              setDeliveryAddressIsInvoiceAddress
            }
            setConfirmDisabled={setConfirmDisabled}
          />
        </ModalContent>
      </div>
      <ModalFooter>
        <ButtonContainer>
          <ConfirmButton
            className="btn-black-blue pap-btn--xl br-4 fl ai-c jc-c fs-14 p-14"
            aria-label="Cancel"
            type="button"
            disabled={confirmDisabled}
            handleClick={setNewAddress}
            label="Cancel">
            Confirm New Address
          </ConfirmButton>
        </ButtonContainer>
      </ModalFooter>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  > div {
    border-radius: 8px;
    padding: 0;
    width: 90%;
    max-width: 530px;

    @media only screen and (min-width: 768px) {
      max-height: 90%;
    }

    > h2 {
      color: #4c4c4c;
      > span {
        color: #7f7f7f;
      }
    }
  }
`;

const ModalHeader = styled.h2`
  font-size: 1.375rem;
  padding: 1.5rem 1.5rem 0 1.5rem;
  margin: 0;
`;

const ModalContent = styled.div`
  flex-direction: column;
  margin: 0;
`;

const ModalFooter = styled.div`
  border-top: 1px solid #e4e4e4;
  padding: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 768px) {
    justify-content: end;
  }
`;

const ConfirmButton = styled(Button)`
  color: white;
  font-weight: 500;
  border: 0;
  padding: 0;
  width: 48%;
  height: 44px;
  @media only screen and (min-width: 768px) {
    width: 25%;
  }
`;

export default CheckoutNewAddressModal;
