import React from 'react';

const ProductsSearchTitle = ({ mode, q, title: propsTitle, productsTotal }) => (
  <div className="container">
    {mode === 'search' ? (
      <h1
        className="product-listings__title col-12 fs-32 fw-500 ta-l mb-0 m-mb-18"
        data-testid="results-title">
        Search results for
        <span className="fw-700 ml-6">&quot;{q}&quot; </span>
        <span className="ml-6 fs-24 fw-400 ta-l c--grey50">
          ({productsTotal} products)
        </span>
      </h1>
    ) : (
      <h1
        className="product-listings__title col-12 fs-24 fw-700 ta-l mb-0 m-mb-18"
        data-testid="results-title">
        {propsTitle}
      </h1>
    )}
  </div>
);

export default ProductsSearchTitle;
