import React from 'react';

const SelectedProduct = ({
  product: {
    id,
    description,
    catalogue,
    price,
    small_image_url: smallImageUrl,
  },
}) => (
  <div className="product-details" key={`product-details-${id}`}>
    <h1>Selected Promotion Product</h1>
    <h3>{description}</h3>
    <h6>Catalogue Ref: {catalogue}</h6>
    <h6 className="price">
      Price: <b>£{Number(price.goods).toFixed(2)}</b>
    </h6>
    <img className="product-image" src={smallImageUrl} alt={description} />
  </div>
);

export default SelectedProduct;
