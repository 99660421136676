import React from 'react';
import ProductSlider from 'components/shared/ProductSlider';
import SliderProduct from './SliderProduct';

const SaleProductsSlider = ({ items }) => (
  <ProductSlider
    numberOfItems={items.length}
    productsPerPage={5}
    options={{
      perPage: 5,
      pagination: false,
      arrows: items.length > 4,
      drag: items.length > 4,
      breakpoints: {
        480: {
          perPage: 2, // Number of items per page for small mobile
          drag: items.length > 2,
        },
        820: {
          perPage: 3, // Number of items per page for tablet
          drag: items.length > 3,
        },
      },
    }}>
    <ul className="splide__list">
      {items.map(item => (
        <SliderProduct key={item.id} item={item} />
      ))}
    </ul>
  </ProductSlider>
);

export default SaleProductsSlider;
