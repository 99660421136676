import React from 'react';
import { AccountIconOutline } from '../shared/CommonSvgs';

const AccountIcon = () => (
  <AccountIconOutline
    className="header-dropdown-button__icon--mobile"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  />
);

export default AccountIcon;
