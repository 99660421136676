export const initialState = {
  products: [],
  searchClassState: 'admin-product-search',
  searchTerm: '',
  selectedProduct: null,
  results: false,
  requestConfig: {
    url: null,
    method: '',
    headers: {
      Accept: 'application/json',
    },
  },
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.payload };
    case 'RESET_STATE':
      return {
        ...state,
        products: [],
        searchClassState: 'admin-product-search',
      };
    default:
      return state;
  }
};
