import React from 'react';

const TimerComponents = ({ timeLeft }) => (
  <span>
    Order in the next{' '}
    <span className="fw-700 c--green">
      {String(timeLeft.hours).padStart(2, '0')}
    </span>
    :
    <span className="fw-700 c--green">
      {String(timeLeft.minutes).padStart(2, '0')}
    </span>
    :
    <span className="fw-700 c--green">
      {String(timeLeft.seconds).padStart(2, '0')}
    </span>{' '}
    for same day despatch.
  </span>
);

export default TimerComponents;
