const setupMainNavigation = () => {
  const menu = document.querySelector('.main-navigation');
  const body = document.querySelector('body');

  const navItems = document.querySelectorAll(
    '.category-nav-item.category__has-children:not(.category-nav-item-highlighted)',
  );

  const menuBackground = document.querySelector('.menu-background');
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  if (menuBackground) {
    menuBackground.addEventListener('mouseenter', () => {
      menuBackground.classList.remove('show');
    });
  }

  for (let i = 0; i < navItems.length; i++) {
    const navItem = navItems[i];
    let menuHoverTimer;
    navItem.addEventListener('mouseenter', () => {
      menuHoverTimer = setTimeout(() => {
        menuBackground.classList.add('show');
      }, 250);
    });

    navItem.addEventListener('mouseleave', e => {
      const target = e.relatedTarget || e.toElement;
      if (target !== null) {
        if (
          target.classList.contains('divider-vertical') ||
          target.classList.contains('menu-background')
        ) {
          menuBackground.classList.add('show');
        } else {
          menuBackground.classList.remove('show');
        }
      }

      clearTimeout(menuHoverTimer);
    });
  }

  const handleMenuToggle = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (width >= 768) {
      menu.classList.remove('site-navigation--visible');
      body.classList.remove('disable-scroll');
      window.removeEventListener('resize', handleMenuToggle);
    }
  };

  if (screenWidth < 768) {
    window.addEventListener('resize', handleMenuToggle);
  }
};

export default setupMainNavigation;
