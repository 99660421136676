import React from 'react';

const CompareProductsToShow = ({ products, location }) => {
  const RenderProducts = () => {
    switch (location) {
      case 'user-compare__product-detail':
        return (
          <>
            {products.length > 3 && (
              <div className="user-compare-bar__product-image-wrapper fl ai-c jc-c">
                <span className="fs-14 c--grey50 ta-c">
                  +{products.length - 3}
                  <br />
                  more
                </span>
              </div>
            )}
            {products.slice(0, 3).map(({ id, imgUrl, url, description }) => (
              <a
                key={id}
                href={url}
                className="user-compare-bar__product-image-wrapper">
                <img
                  width="64"
                  height="64"
                  src={imgUrl}
                  alt={description}
                  className="user-compare-bar__product-image"
                />
              </a>
            ))}
          </>
        );
      default:
        return (
          <>
            {products.map(({ id, imgUrl, url, description }) => (
              <a
                key={id}
                href={url}
                className="user-compare-bar__product-image-wrapper">
                <img
                  width="64"
                  height="64"
                  src={imgUrl}
                  alt={description}
                  className="user-compare-bar__product-image"
                />
              </a>
            ))}
          </>
        );
    }
  };

  return <RenderProducts />;
};

export default CompareProductsToShow;
