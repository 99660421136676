import { useState, useEffect } from 'react';
import { useHTTP } from 'hooks/useRequest';

const useRemoveFavourite = (id, onSuccess, onError) => {
  const [removeProduct, setRemoveProduct] = useState(false);

  const requestConfig = {
    url: removeProduct
      ? `/catalogue/products/${id}/remove_from_favourite`
      : null,
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  };

  const { error, data } = useHTTP(requestConfig);

  useEffect(() => {
    if (error) {
      if (onError) {
        onError(error);
      }
    } else if (data) {
      if (onSuccess) {
        onSuccess(data);
      }
    }
  }, [data, error, onSuccess, onError]);

  const handleRemove = () => {
    setRemoveProduct(true);
  };

  return { handleRemove };
};

export default useRemoveFavourite;
