import React, { useEffect, useState } from 'react';
import { useCookie } from 'hooks/useCookies';
import usePricingStore from 'stores/pricingStore/usePricingStore';
import PriceCalc from 'components/PriceCalc';
import HighlightSearchTerm from './HighlightSearchTerm';

const Suggestion = ({
  signedIn,
  searchTerm,
  url,
  id,
  stockCode,
  catalogue,
  thumbnail,
  description,
  showCatalogue,
  showStockCode,
  showPrice,
  showVat,
  minimumOrder,
  stock,
}) => {
  const { autocompletePrices, autocompletePricingError } = usePricingStore(
    state => ({
      autocompletePrices: state.autocompletePrices,
      autocompletePricingError: state.autocompletePricingError,
    }),
  );

  const [price, setPrice] = useState('');

  const [errorMessage, setErrorMessage] = useState();

  const [pricingCookieValue] = useCookie('show_pricing');
  const parsedPricingCookie = JSON.parse(pricingCookieValue);

  useEffect(() => {
    if (autocompletePrices) {
      const currentProductPrice = autocompletePrices[id];

      if (currentProductPrice) {
        const { cheapest_price: productCheapestPrice } = currentProductPrice;

        setPrice(productCheapestPrice);
      } else if (autocompletePricingError) {
        const { error: pricingError } = autocompletePricingError;
        setErrorMessage(pricingError);
      }
    }
  }, [autocompletePrices]);

  const descriptionHighlighted = (
    <HighlightSearchTerm resultText={description} searchTerm={searchTerm} />
  );

  return (
    <a
      href={url || `/products/${id}`}
      className="autocomplete__product product"
      key={id}
      data-product-id={id}
      data-product-stock-code={stockCode}
      data-product-catalogue={catalogue}>
      <div className="autocomplete__product-image product-image">
        {thumbnail !== '' && (
          <img src={thumbnail} alt="" width="32" height="32" />
        )}
      </div>
      <div className="autocomplete__product-content product-content">
        <h3 className="autocomplete__product-title ff--rc td-none">
          {descriptionHighlighted}
        </h3>
        <div>
          {showCatalogue && (
            <span className="autocomplete__product-code">
              Part Code: <strong>{catalogue}</strong>
            </span>
          )}
          {showStockCode && (
            <span className="autocomplete__product-code">
              <strong>Stock Code</strong>: {stockCode}
            </span>
          )}
        </div>
      </div>
      {parsedPricingCookie && signedIn && (
        <div className="autocomplete__product-price product-price ml-8">
          {showPrice && (
            <PriceCalc
              stock={stock}
              showPriceBox
              qty={minimumOrder}
              price={price}
              isProductList
              showVat={showVat}
            />
          )}
          {errorMessage && showPrice && <p>no price found</p>}
        </div>
      )}
    </a>
  );
};

export default Suggestion;
