import React from 'react';
import {
  AutocompleteIcon,
  AutocompleteCloseIcon,
} from 'components/shared/CommonSvgs';
import useAutoComplete from 'hooks/useAutoComplete';
import Suggestions from './suggestions';
import CategoryResults from './categoryResults';
import SkeletonLoadingProduct from './SkeletonLoadingProduct';
import SiteSearchNoResults from './noResults';

import { grey70 } from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const AutoComplete = ({
  signedIn,
  hideEllipsis,
  numToShow,
  showPrice,
  showVat,
  showStockCode,
  showCatalogue,
  basketUrl,
  isProductList,
  basketId,
  showDescriptionBrand,
  searchUrl,
  startSearch,
  overlay = true,
  searchPlaceholder: propsSearchPlaceholder,
}) => {
  const {
    loading,
    results,
    categories,
    searchTerm,
    setSearchTerm,
    searchPlaceholder,
    closeSearch,
    handleSubmit,
  } = useAutoComplete({
    isProductList,
    startSearch,
    searchUrl,

    propsSearchPlaceholder,
  });

  const Overlay = () => (
    <div className={`overlay ${searchTerm.length && 'overlay--is-on'}`} />
  );

  const skeletons = Array.from({ length: 5 }, (_, i) => (
    <SkeletonLoadingProduct key={i} />
  ));

  return (
    <div
      data-testid="searchAutocompleteWrapper"
      className={
        searchTerm.length
          ? 'search-autocomplete search-autocomplete__wrapper search-autocomplete--active'
          : 'search-autocomplete'
      }>
      {overlay && <Overlay />}
      <div className="search-autocomplete--active__wrapper">
        <div
          onKeyDown={e => e.keyCode === 13 && handleSubmit(e)}
          data-testid="searchAutocomplete"
          className={`search-autocomplete__inner
          ${!!searchTerm.length && 'search-autocomplete__inner--is-on'}`}
          role="searchbox"
          tabIndex={0}>
          {!searchTerm && (
            <div className="search-autocomplete__start-icon">
              <AutocompleteIcon
                className="search-autocomplete__icon"
                width="18"
                height="18"
                fillColor={grey70}
              />
            </div>
          )}
          <input
            type="text"
            aria-label="search"
            data-testid="searchAutocompleteInput"
            className={
              searchTerm.length
                ? 'search-autocomplete__input search-autocomplete__input--active'
                : 'search-autocomplete__input'
            }
            onKeyDown={({ target }) => setSearchTerm(target.value)}
            onChange={({ target }) => setSearchTerm(target.value)}
            onPaste={({ target }) => setSearchTerm(target.value)}
            name="search"
            placeholder={
              hideEllipsis ? searchPlaceholder : `${searchPlaceholder} ...`
            }
          />
          {searchTerm && (
            <>
              <button
                type="button"
                label="Close"
                aria-label="Close"
                data-testid="searchAutocompleteClose"
                className="search-autocomplete__action search-autocomplete__action--close b-none"
                onClick={() => closeSearch()}>
                <AutocompleteCloseIcon
                  className="search-autocomplete__icon search-autocomplete__close"
                  width="14"
                  height="14"
                  fillColor={grey70}
                />
              </button>

              <button
                type="button"
                label="Search"
                aria-label="Search"
                className="search-autocomplete__action"
                onClick={() => handleSubmit()}>
                <AutocompleteIcon
                  className="search-autocomplete__icon"
                  width="18"
                  height="18"
                  fillColor={grey70}
                />
              </button>
            </>
          )}
        </div>
        <div
          className={`search-autocomplete__suggestions
              search-suggestions__wrapper
              search-autocomplete__suggestions--full-width
              ${!!searchTerm.length && 'search-autocomplete__suggestions--is-on'}`}>
          {!!categories.length && (
            <CategoryResults categories={categories} searchTerm={searchTerm} />
          )}
          {searchTerm.length >= startSearch &&
          results.length > 0 &&
          !loading ? (
            <>
              <Suggestions
                signedIn={signedIn}
                closeSearch={() => closeSearch()}
                numToShow={numToShow}
                results={results}
                searchTerm={searchTerm}
                showPrice={showPrice}
                showStockCode={showStockCode}
                showCatalogue={showCatalogue}
                basketUrl={basketUrl}
                basketId={basketId}
                showVat={showVat}
                showDescriptionBrand={showDescriptionBrand}
              />
            </>
          ) : (
            loading && skeletons
          )}

          {results.length === 0 &&
            searchTerm.length >= startSearch &&
            !loading && (
              <div className="suggestions">
                <SiteSearchNoResults />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default AutoComplete;
