import React from 'react';

const LimitedPod = props => (
  <div
    className={`product-special-offer col-6 col-sm-6 col-md-3 fade-in offer-${this.props.offer}`}>
    {this.props.children}
  </div>
);

export default LimitedPod;
