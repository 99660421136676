/**
 * Wrapper around axios to set the CSRF token header for rails API calls
 */
import axios from 'axios';
import * as rax from 'retry-axios';

const token =
  typeof document !== 'undefined'
    ? document.querySelector('[name="csrf-token"]') || {
        content: 'no-csrf-token',
      }
    : { content: 'no-csrf-token' };

const ax = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    common: {
      'X-CSRF-Token': token.content,
    },
  },
});

// Setting up retry-axios - using default config
// https://www.npmjs.com/package/retry-axios
ax.defaults.raxConfig = {
  instance: ax,
};

rax.attach(ax);

export default ax;
