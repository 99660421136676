import React from 'react';

const ResponsiveImage = ({ srcSet, alt, fallback, className }) => (
  <picture>
    {srcSet.map(({ src, type, media, id }) => (
      <source
        key={id}
        srcSet={src}
        type={type}
        media={media}
        width="1294"
        height="336"
      />
    ))}
    <img src={fallback} alt={alt} className={className} />
  </picture>
);

export default ResponsiveImage;
