import React from 'react';

import OfferPod from './offerPod';

const OfferContent = props => {
  if (props.display_pricing == 'true') {
    var pricing = (
      <div className="product-body">
        <p className="product-text product-price product-old-price">
          Was £{Number(props.data.product.lowest_price.goods).toFixed(2)}*
        </p>
        <p className="product-text product-price product-promo-price">
          Now £
          {Number(props.data.product.lowest_limited_price.goods).toFixed(2)}
        </p>
      </div>
    );
  }
  return (
    <OfferPod offer={props.offer}>
      <figure className="product-img">
        <a href={props.data.product.product_url}>
          <img className="img-full" src={props.data.product.medium_image_url} />
        </a>
      </figure>

      <header className="product-header">
        <h6 className="product-desc">
          <a href={props.data.product.product_url}>
            {props.data.product.description}
          </a>
        </h6>
      </header>

      {pricing}

      <h5 className="product-callout-countdown">
        <span className="countdown" data-countdown={props.data.end_time}></span>
        <br />
        <span className="left-to-buy">Left to buy</span>
      </h5>
    </OfferPod>
  );
};

export default OfferContent;
