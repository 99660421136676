export const initialState = {
  postcodeLookupError: false,
  valid: false,
  searching: false,
  invalidAddress: false,
  addressesFound: [],
  requestConfig: {
    url: null,
    method: null,
  },
};

// Reducer function to handle state transitions
export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_POSTCODE_LOOKUP_ERROR':
      return { ...state, postcodeLookupError: action.payload };
    case 'SET_VALID':
      return { ...state, valid: action.payload };
    case 'SET_SEARCHING':
      return { ...state, searching: action.payload };
    case 'SET_INVALID_ADDRESS':
      return { ...state, invalidAddress: action.payload };
    case 'SET_ADDRESSES_FOUND':
      return { ...state, addressesFound: action.payload };
    case 'SET_REQUEST_CONFIG':
      return { ...state, requestConfig: action.payload };
    case 'RESET':
      return { ...state, addressesFound: [] };
    case 'SET_ERROR_STATE':
      return {
        ...state,
        postcodeLookupError: true,
        searching: false,
        addressesFound: [],
        requestConfig: { url: null, method: null },
      };
    case 'SET_DATA_STATE':
      return {
        ...state,
        postcodeLookupError: false,
        invalidAddress: false,
        addressesFound: action.payload,
        searching: false,
        requestConfig: { url: null, method: null },
      };
    default:
      return state;
  }
};
