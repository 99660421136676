import { create } from 'zustand';

const gtmStore = set => ({
  itemData: {},
  setCurrentItemData: itemData => {
    set(() => ({
      itemData,
    }));
  },
});

const useGTMStore = create(gtmStore);

export default useGTMStore;
