import { useEffect } from 'react';
import usePillStore from 'stores/pillStore/usePillStore';

const useDropdown = pillIndex => {
  const { activePill, setActivePill } = usePillStore();

  const handleDocumentClick = e => {
    // Check if the click was inside an open dropdown
    const isClickInsideDropdown = e.target.closest(
      '.header-dropdown-button-dropdown--is-open',
    );

    if (!isClickInsideDropdown) {
      setActivePill(0); // Close the dropdown if the click was outside
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [activePill]);

  const handleClick = e => {
    e.stopPropagation();

    if (activePill === pillIndex) {
      setActivePill(0);
    } else {
      setActivePill(pillIndex);
    }
  };

  return {
    activePill,
    handleClick,
    isOpen: activePill === pillIndex,
  };
};

export default useDropdown;
