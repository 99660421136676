import React, { useState, useEffect } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import useBasketStore from 'stores/basketStore/useBasketStore';
import { addressFormatter } from 'helpers/utils';
import { useHTTP } from 'hooks/useRequest';
import { CircleCheck } from 'components/shared/CommonSvgs';
import { pushToDataLayer } from 'components/shared/PushToDatalayer';
import { blackBlue85 } from '../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const ConfirmationRow = ({ handleEdit, label, value, step }) => {
  return (
    <div className="confirmation-row fl ai-c jc-sb p-16">
      <div className="fl fl-col width-100">
        <div className="confirmation-row__label mb-5 fs-16 fw-700">{label}</div>
        <p className="confirmation-row__value m-0 fs-14">{value}</p>
      </div>
      {handleEdit && (
        <Button
          className="checkout__step-edit"
          handleClick={() => handleEdit(step)}>
          Edit
        </Button>
      )}
    </div>
  );
};

const Confirm = ({ invoiceAddress, itemData }) => {
  const {
    basket,
    deliveryOption,
    setOrderComplete,
    setCurrentBasket,
    setCurrentStep,
    deliveryAddressIsInvoiceAddress,
  } = useBasketStore(state => ({
    basket: state.basket,
    deliveryOption: state.deliveryOption,
    setOrderComplete: state.setOrderComplete,
    setCurrentBasket: state.setCurrentBasket,
    setCurrentStep: state.setCurrentStep,
    deliveryAddressIsInvoiceAddress: state.deliveryAddressIsInvoiceAddress,
  }));
  const { additional_information: additionalInfo } = basket;

  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: null,
  });

  const { data } = useHTTP(requestConfig);

  const handleEdit = step => {
    setRequestConfig({
      url: '/checkout',
      method: 'put',
      data: { basket: { step: step.toLowerCase() } },
    });
  };

  const handleUpdate = () => {
    setRequestConfig({
      url: '/checkout',
      method: 'put',
      data: {
        basket: {
          step: 'complete',
        },
      },
    });
  };

  useEffect(() => {
    /* Set order complete if a path is returned from the backend,
    only happens when an order is complete */
    if (data?.path) {
      pushToDataLayer('purchase', itemData, {
        value: basket?.goods_total,
        tax: basket?.vat,
        shipping: deliveryOption?.value,
        shipping_tier: deliveryOption?.label,
        address_option: deliveryAddressIsInvoiceAddress
          ? 'invoice_address'
          : 'address_book_address',
      });
      setOrderComplete({ complete: true, url: data.path });
    } else if (data) {
      // Update basket and step when handle edit is fired
      const { basket: basketData } = data;
      setCurrentBasket(basketData);
      setCurrentStep(basketData.step.toUpperCase());
      setRequestConfig({
        url: null,
        method: null,
      });
    }
  }, [data]);

  return (
    <div className="checkout__panel-content fl fl-col">
      <div className="confirmation-rows col-12 b-full b-colour--grey br-4">
        <ConfirmationRow
          label="Invoice Address"
          value={addressFormatter(invoiceAddress)}
          step="DELIVERY_ADDRESS"
        />
        <ConfirmationRow
          handleEdit={handleEdit}
          label="Delivery Address"
          value={addressFormatter(basket)}
          step="DELIVERY_ADDRESS"
        />
        <ConfirmationRow
          handleEdit={handleEdit}
          label="Delivery Options"
          value={deliveryOption.label}
          step="ADDITIONAL_INFORMATION"
        />
        <ConfirmationRow
          handleEdit={handleEdit}
          label="Additional Instructions"
          value={additionalInfo || '-'}
          step="ADDITIONAL_INFORMATION"
        />
      </div>
      <Button
        handleClick={() => handleUpdate()}
        className="btn btn-orange pap-btn--high mtb-16 plr-16 as-fs m-as-fe fw-700 br-4">
        <span>Complete Order</span>
        <CircleCheck
          className="ml-6"
          fillColor={blackBlue85}
          width="16"
          height="16"
        />
      </Button>
    </div>
  );
};

export default Confirm;
