import React, { useState, useEffect } from 'react';
import { useGET } from 'hooks/useRequest';
import useUserCompareStore from 'stores/userCompareStore/useUserCompareStore';
import useToastStore from 'stores/useToastStore';

const AddToCompare = ({
  className,
  currentCompareProducts,
  product,
  product: { step_id: id },
  title,
  url = `/catalogue/products/${product.id}/add_to_compare`,
  removeUrl = `/catalogue/products/${product.id}/remove_from_compare`,
}) => {
  const [checked, setChecked] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const [removeProduct, setRemoveProduct] = useState(false);

  const { userCompareProducts, addCurrentCompareProducts } =
    useUserCompareStore(state => ({
      userCompareProducts: state.userCompareProducts,
      addCurrentCompareProducts: state.addCurrentCompareProducts,
    }));
  const { addToast } = useToastStore();

  const { error: addError, data: addData } = useGET(addProduct ? url : null);
  const { error: removeError, data: removeData } = useGET(
    removeProduct ? removeUrl : null,
  );

  useEffect(() => {
    if (addError) {
      addToast('Error adding to compare', null, 'decline');
    }
    if (addData) {
      const { message, products } = addData;
      addToast(message);
      addCurrentCompareProducts(products);
    }
  }, [addError, addData]);

  useEffect(() => {
    if (removeError) {
      addToast('Error removing from compare', null, 'decline');
    }
    if (removeData) {
      const { message, products } = removeData;
      addToast(`${message} from compare.`);
      addCurrentCompareProducts(products);
    }
  }, [removeError, removeData]);

  useEffect(() => {
    if (currentCompareProducts) {
      addCurrentCompareProducts(currentCompareProducts);
    }
  }, [currentCompareProducts]);

  useEffect(() => {
    const compareProduct = id || product.id;
    setChecked(userCompareProducts.some(item => item.id === compareProduct));
  }, [userCompareProducts]);

  const handleCheckboxChange = ({ target: { checked: inputChecked } }) => {
    setChecked(inputChecked);
    setAddProduct(inputChecked);
    setRemoveProduct(!inputChecked);
  };

  return (
    <div className={`checkbox-with-title ${className || ''}`}>
      <label className="fl ai-c" htmlFor={`add-to-compare-${id || product.id}`}>
        <input
          id={`add-to-compare-${id || product.id}`}
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
        />
        <span className="fs-14 c--grey70">{title}</span>
      </label>
    </div>
  );
};

export default AddToCompare;
