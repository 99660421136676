import React from 'react';
import { WhiteCheck } from '../shared/CommonSvgs';

const AccountActive = ({ fillColour, width, height }) => (
  <WhiteCheck
    fillColor={fillColour}
    width={width}
    height={height}
    viewBox="0 0 16 13"
  />
);

export default AccountActive;
