import React, { useEffect } from 'react';
import Toast from 'components/ToastNotifications/Toast';

const ToastContainer = ({ toasts, removeToast }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      if (toasts.length > 0) {
        removeToast(toasts[0].id);
      }
    }, 5000); // Remove toast after 5 seconds

    return () => clearInterval(timer);
  }, [toasts, removeToast]);

  return (
    <>
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          description={toast.description}
          message={toast.message}
          type={toast.type}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </>
  );
};

export default ToastContainer;
