import React from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import useRemoveCompareProduct from 'hooks/useDeleteCompareProduct';
import { DeleteIcon } from 'components/shared/CommonSvgs';

const DeleteCompareProduct = ({ id }) => {
  const onSuccess = () => {
    window.location.reload();
  };

  const { handleRemove } = useRemoveCompareProduct(id, onSuccess);

  return (
    <Button
      className="delete-compare-product"
      type="button"
      aria-label="Remove Compare Product"
      label="Remove Compare Product"
      handleClick={handleRemove}>
      <DeleteIcon width="16" height="18" />
    </Button>
  );
};

export default DeleteCompareProduct;
