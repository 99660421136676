import React from 'react';
import styled from 'styled-components';

const ProductsLoading = ({ loading }) => (
  <ProductsLoadingOverlay
    className={
      'product-listings__loader-overlay product-listings__loader-overlay--' +
      (loading === false ? 'hidden' : '')
    }>
    <Loader className="product-listings__loader">Loading</Loader>
  </ProductsLoadingOverlay>
);

const ProductsLoadingOverlay = styled.div`
  align-items: center;
  background-color: rgba(88, 88, 88, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  left: 0px;
  top: 0px;
  transition: all 0.25s ease-in-out;
  width: 100vw;
  z-index: 999;

  &.product-listings__loader-overlay--hidden {
    display: none;
  }
`;

const Loader = styled.div`
  animation: load8 1.1s infinite linear;
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  border-radius: 50%;
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  font-size: 10px;
  height: 10em;
  margin: 60px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 10em;

  &:after {
    border-radius: 50%;
    height: 10em;
    width: 10em;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default ProductsLoading;
