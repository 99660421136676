import { create } from 'zustand';

const userFavouritesStore = (set, get) => ({
  userFavouritesProducts: [],
  isDuplicate: false,
  addCurrentFavouriteProducts: currentProducts => {
    set(() => ({
      userFavouritesProducts: currentProducts,
    }));
  },
  addFavouriteProduct: product => {
    const products = get().userFavouritesProducts;
    const productExists = products.some(item => item.id === product.id);
    let updatedProducts;
    if (productExists) {
      updatedProducts = products.filter(p => p.id !== product.id);
    }

    set(() => ({
      isDuplicate: productExists,
      userFavouritesProducts: productExists
        ? updatedProducts
        : [...products, product],
    }));
  },
  removeFavouriteProduct: id => {
    const products = get().userFavouritesProducts;
    const updatedProducts = products.filter(product => product.id !== id);

    set(() => ({
      userFavouritesProducts: updatedProducts,
    }));
  },
});

const useUserFavouritesStore = create(userFavouritesStore);

export default useUserFavouritesStore;
