import React, { useEffect, useState } from 'react';
import { ToolTip } from '@cityelectricalfactors/ui-components';
import useBasketStore from 'stores/basketStore/useBasketStore';
import { useHTTP } from 'hooks/useRequest';
import { InfoIcon } from 'components/shared/CommonSvgs';
import NextStep from './NextStep';
import { blackBlue85 } from '../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const DeliveryOptions = ({ itemData }) => {
  const {
    setDeliveryOption,
    setPurchaseOrder,
    setAdditionalInfo,
    deliveryOption,
    purchaseOrder,
    additionalInfo,
    basket,
    deliveryAddressIsInvoiceAddress,
  } = useBasketStore(state => ({
    setDeliveryOption: state.setDeliveryOption,
    setPurchaseOrder: state.setPurchaseOrder,
    setAdditionalInfo: state.setAdditionalInfo,
    deliveryOption: state.deliveryOption,
    purchaseOrder: state.purchaseOrder,
    additionalInfo: state.additionalInfo,
    basket: state.basket,
    deliveryAddressIsInvoiceAddress: state.deliveryAddressIsInvoiceAddress,
  }));

  const [selectItems, setSelectItems] = useState([]);
  const { data } = useHTTP({
    url: `/delivery_options`,
    method: 'GET',
  });

  useEffect(() => {
    if (data?.delivery_options) {
      setSelectItems(data.delivery_options);
      setDeliveryOption(data.delivery_options[0]);
    }
  }, [data]);

  const handleSettingDeliveryZone = optionLabel => {
    setDeliveryOption(selectItems.find(item => item.label === optionLabel));
  };

  return (
    <div className="row checkout__panel-content">
      <div className="col-12">
        <div className="m-row">
          <select
            className="custom-select delivery-option__select col-6 p-12
            width-100 b-full b-colour--grey50 br-4"
            onChange={e => {
              handleSettingDeliveryZone(e.target.selectedOptions[0].text);
            }}>
            {selectItems.map(({ delivery_zone_id: deliveryZoneId, label }) => (
              <option key={deliveryZoneId} value={deliveryZoneId}>
                {label}
              </option>
            ))}
          </select>

          <div
            className="col-6 pos-rel fl ai-c p-10 pl-6 mt-20 m-mt-0
                          b-full b-colour--grey50 br-4">
            <div className="fl ai-c width-100">
              <label
                id="purchase-order-number-label"
                htmlFor="purchase-order-number">
                <input
                  id="purchase-order-number"
                  value={purchaseOrder}
                  className="purchase-order-number__value no-input-outline pt-12 width-100 b-none"
                  onChange={e => setPurchaseOrder(e.target.value)}
                  placeholder=" "
                />
                <span className="purchase-order-number__label pos-absolute c--grey50 fs-14">
                  Purchase Order Number
                </span>
              </label>
            </div>
            <ToolTip
              className="purchase-order__tooltip p-16 jc-fs ta-l br-8 fs-14 ta-left c--white"
              toolTipIcon={<InfoIcon />}
              bgColor={blackBlue85}>
              This is the unique number assigned to a purchase order. If you do
              not use purchase orders, simply add text as reference.
            </ToolTip>
          </div>
        </div>
        <div className="row mtb-20">
          <textarea
            value={additionalInfo}
            className="additional-instructions col-12 p-10 b-full b-colour--grey50 br-4 ff--body"
            placeholder="Additional Instructions"
            onChange={e => setAdditionalInfo(e.target.value)}
          />
        </div>
      </div>

      <NextStep
        nextStepData={{
          basket: {
            additional_instructions: additionalInfo,
            purchase_order: purchaseOrder,
            delivery_zone_id: deliveryOption?.delivery_zone_id,
            step: 'confirm',
          },
          gtmData: {
            itemData,
            event: 'add_shipping_info',
            additionalData: {
              value: basket?.goods_total,
              shipping_tier: deliveryOption?.label,
              address_option: deliveryAddressIsInvoiceAddress
                ? 'invoice_address'
                : 'address_book_address',
            },
          },
        }}
        continueCondition={!!deliveryOption}
      />
    </div>
  );
};

export default DeliveryOptions;
