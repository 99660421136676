import React from 'react';
import { OutOfStockIcon } from 'components/shared/CommonSvgs';
import { orange10 } from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const Alert = ({ amount, message }) => (
  <div className="product-stock fl mtb-12 p-6 fs-14 b-full b-colour--orange bgc--orange br-2">
    <OutOfStockIcon
      className="mr-4"
      width="20"
      height="20"
      fillColor={orange10}
    />
    <p className="product-stock__number-wrapper fl fl-col nm np">
      <span className="product-stock__number-content">
        <span className="product-stock__number-value mr-4">
          {message} {amount}
        </span>
      </span>
    </p>
  </div>
);

export default Alert;
