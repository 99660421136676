import React from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import useRemoveFavourite from 'hooks/useDeleteUserFavourite';
import { DeleteIcon } from 'components/shared/CommonSvgs';

const DeleteUserFavourite = ({ id }) => {
  const onSuccess = () => {
    window.location.reload();
  };

  const { handleRemove } = useRemoveFavourite(id, onSuccess);

  return (
    <Button
      className="delete-saved-product"
      type="button"
      aria-label="Remove Saved Product"
      label="Remove Saved Product"
      handleClick={handleRemove}>
      <DeleteIcon width="16" height="18" />
    </Button>
  );
};

export default DeleteUserFavourite;
