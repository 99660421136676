import React, { useEffect, useState } from 'react';
import LoadingSpinnerComponent from 'components/shared/LoadingSpinnerComponent';
import TimerComponents from './TimerComponents';

const calculateTimeLeft = () => {
  const now = new Date();
  const targetTime = new Date();
  targetTime.setHours(15, 0, 0, 0); // Set target time to 3 PM today

  let past3PM = false;

  if (now >= targetTime) {
    // If it's already past 3 PM today, set target to 3 PM tomorrow
    targetTime.setDate(targetTime.getDate() + 1);
    past3PM = true;
  }

  const difference = targetTime - now;

  let timeLeftTillEnd = {};

  if (difference > 0) {
    timeLeftTillEnd = {
      hours: Math.floor(difference / (1000 * 60 * 60)),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    timeLeftTillEnd = null;
  }

  return { time: timeLeftTillEnd, past3PM };
};

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [isPast3PM, setIsPast3PM] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const { time, past3PM } = calculateTimeLeft();
    setTimeLeft(time);
    setIsPast3PM(past3PM);
    setIsLoading(false);

    const timer = setInterval(() => {
      const { time: updatedTime, past3PM: updatedPast3PM } =
        calculateTimeLeft();
      setTimeLeft(updatedTime);
      setIsPast3PM(updatedPast3PM);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (isLoading) {
    return <LoadingSpinnerComponent />;
  }

  return (
    <div>
      {isPast3PM ? (
        <span>Order now for next working day despatch.</span>
      ) : (
        <TimerComponents timeLeft={timeLeft} />
      )}
    </div>
  );
};

export default CountdownTimer;
