import React from 'react';

const OfferPod = props => (
  <div
    className={`product-special-offer col-6 col-sm-6 col-md-3 fade-in offer-${props.offer}`}>
    {props.children}
  </div>
);

export default OfferPod;
