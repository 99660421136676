import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { Button } from '@cityelectricalfactors/ui-components';
import { CrossIcon, AutocompleteCloseIcon } from 'components/shared/CommonSvgs';
import ProductAggregate from './aggregate';
import {
  grey50,
  grey70,
} from '../../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const ProductAggregations = ({
  aggregations,
  applied_aggregates: appliedAggregates,
  type,
  products_total: productsTotal,
  toggleAggregate,
  resetAllAggregates,
  collapseAllFilters,
  showCheckbox,
  useArrowIcons,
  closeMobileAgg,
}) => {
  const [stateAggregations, setStateAggregations] = useState([]);

  const getDisplayValue = (newType, val) => {
    let display = '';
    if (aggregations[newType] !== undefined) {
      display = aggregations[newType].buckets.filter(agg => {
        if (agg.value !== val) {
          return false;
        }
        return agg;
      });
    }

    return display;
  };

  const formatAggregationTitle = key => `${key.replace(/_/g, ' ')}`;

  const formatAggregations = aggs => {
    let updatedAggregations = [];
    Object.keys(aggs).forEach(key => {
      const formattedOptions = [];
      aggs[key].buckets.forEach(agg => {
        if (agg.key === '') return;
        agg.selected = false;
        if (appliedAggregates[key] !== undefined) {
          if (
            appliedAggregates[key].includes(agg.value) ||
            appliedAggregates[key].includes(decodeURIComponent(agg.value))
          ) {
            agg.selected = true;
          }
        }
        formattedOptions.push(agg);
      });

      updatedAggregations.push({
        title: formatAggregationTitle(key),
        options: formattedOptions,
      });
    });

    updatedAggregations.sort((a, b) => {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    if (type === 'category' || type === 'brand') {
      updatedAggregations = updatedAggregations.filter(
        agg => agg.title !== type,
      );
    }

    setStateAggregations(updatedAggregations);
  };

  useEffect(() => {
    formatAggregations(aggregations);
  }, [aggregations]);

  const newAppliedAggregates = [];

  Object.keys(appliedAggregates).forEach(key => {
    const appliedAggregatesUniqueId = uuidv4();
    appliedAggregates[key].forEach(val => {
      let displayAggTitle = val;
      let count = 0;
      if (aggregations[key] !== undefined) {
        const value = aggregations[key].buckets.filter(agg => {
          if (key === 'category' || key === 'brand') {
            return agg.value === val;
          }
          return agg.key === val || encodeURIComponent(agg.key) === val;
        });
        count = value[0] === undefined ? 0 : value[0].doc_count;
      }

      if (key === 'brand') {
        displayAggTitle = getDisplayValue('brand', val);
        if (displayAggTitle.length > 0) {
          displayAggTitle = displayAggTitle[0].key;
        }
      }

      if (key === 'category') {
        displayAggTitle = getDisplayValue('category', val);
        if (displayAggTitle.length > 0) {
          displayAggTitle = displayAggTitle[0].key;
        }
      }

      if (count > 0) {
        newAppliedAggregates.push(
          <li
            className="applied-aggregate fl ai-c m-5 br-100 
            c--grey50 bgc--grey b-full b-colour--grey50"
            key={`${val}-${appliedAggregatesUniqueId}`}>
            <button
              type="button"
              className="fl ai-c m-0 bgc--grey b-none c--black-blue"
              onClick={() => toggleAggregate(key, val, true, true)}
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}>
              <span className="mr-10 c--black-blue">
                {decodeURIComponent(displayAggTitle)}
              </span>
              <CrossIcon fillColor={grey50} />
            </button>
          </li>,
        );
      } else if (productsTotal > 0) {
        toggleAggregate(key, val, true, true);
      }
    });
  });

  const aggsApplied =
    newAppliedAggregates.length > 0 ? (
      <ul className="aggregation__options aggregation__options--selected fl fw-w mr-5 np ls-none">
        {newAppliedAggregates}
      </ul>
    ) : (
      ''
    );

  const ClearAggregations = () =>
    Object.keys(appliedAggregates).length > 0 && (
      <button
        type="button"
        className="p-10 bg-none b-none hover-button td-u fs-14 fw-500 c--blue"
        onClick={e => {
          e.preventDefault();
          resetAllAggregates();
        }}>
        Clear All
      </button>
    );

  return (
    <>
      <div className="aggregations__header fl ai-c jc-sb mt-30 mb-12 fw-700">
        <span>{aggsApplied ? 'Selected Filters' : 'Filters'}</span>
        <ClearAggregations />

        <CloseAggregationsButton
          handleClick={closeMobileAgg}
          className="d-flex m-d-none ai-c jc-c bg-none">
          <AutocompleteCloseIcon
            className="search-autocomplete__icon search-autocomplete__close"
            width="14"
            height="14"
            fillColor={grey70}
          />
        </CloseAggregationsButton>
      </div>

      {aggsApplied}
      {stateAggregations.map(agg => {
        const stateAggregationsUniqueId = uuidv4();
        return (
          <ProductAggregate
            collapseAllFilters={collapseAllFilters}
            key={`${agg.title}-${stateAggregationsUniqueId}`}
            agg={agg}
            toggleAggregate={toggleAggregate}
            type={type}
            showCheckbox={showCheckbox}
            useArrowIcons={useArrowIcons}
          />
        );
      })}
    </>
  );
};

const CloseAggregationsButton = styled(Button)`
  display: flex;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export default ProductAggregations;
