import {
  SET_MULTIPLES_OF,
  SET_QUANT_LESS_THAN_MIN_ORDER,
  SET_QUANT_MORE_THAN_MAX_QUANT,
  SET_QUANT_CORRECT,
  SET_EMPTY_QUANT,
  SET_INPUT_CHANGE,
  SET_BACKORDER_MODAL,
  SET_PRODUCT_ADDED,
  SET_ADDING_PRODUCT,
} from './addToBasketActionTypes';

export const setMultiplesOf = (minOrder, isBasketLine) => ({
  type: SET_MULTIPLES_OF,
  payload: { minOrder, isBasketLine },
});

export const setQuantLessThanMinOrder = minOrder => ({
  type: SET_QUANT_LESS_THAN_MIN_ORDER,
  payload: minOrder,
});

export const setQuantMoreThanMaxQuant = maximumQuantity => ({
  type: SET_QUANT_MORE_THAN_MAX_QUANT,
  payload: maximumQuantity,
});

export const setEmptyQuant = () => ({
  type: SET_EMPTY_QUANT,
});

export const setInputChange = quantity => ({
  type: SET_INPUT_CHANGE,
  payload: quantity,
});

export const setQuantCorrect = count => ({
  type: SET_QUANT_CORRECT,
  payload: { count },
});

export const setBackorderModal = (showModal, insufficientStock) => ({
  type: SET_BACKORDER_MODAL,
  payload: { showModal, insufficientStock },
});

export const setProductAdded = (basketSuccess, addProduct, loading) => ({
  type: SET_PRODUCT_ADDED,
  payload: { basketSuccess, addProduct, loading },
});

export const setAddingProduct = (addProduct, loading) => ({
  type: SET_ADDING_PRODUCT,
  payload: { addProduct, loading },
});
