import { create } from 'zustand';

const pricingStore = set => ({
  autocompletePrices: [],
  productListPrices: [],
  pricingError: {},

  setAutocompleteProductPrices: autocompletePrices => {
    set(() => ({
      autocompletePrices,
    }));
  },
  setProductListPrices: productListPrices => {
    set(() => ({
      productListPrices,
    }));
  },
  setProductPricingError: pricingError => {
    set(() => ({
      pricingError,
    }));
  },
});

const usePricingStore = create(pricingStore);

export default usePricingStore;
