import React from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * Highlights occurrences of a search term within a result string by wrapping them in a span tag.
 *
 * @param {Object} props - The component props.
 * @param {string} props.searchTerm - The term to search for within the result string.
 * @param {string} props.resultText - The string to search within.
 * @returns {JSX.Element} - The modified result with highlighted search term or the original result.
 */
const HighlightSearchTerm = ({ searchTerm, resultText }) => {
  if (!searchTerm || !resultText) return <>{resultText}</>;

  // Convert search term to lower case for case-insensitive comparison
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  // Split the resultText into parts without using RegExp
  const parts = [];
  let lastIndex = 0;
  let currentIndex = resultText
    .toLowerCase()
    .indexOf(lowerCaseSearchTerm, lastIndex);

  while (currentIndex !== -1) {
    // Add the text before the match
    parts.push(resultText.slice(lastIndex, currentIndex));
    // Add the matched text
    parts.push(
      resultText.slice(currentIndex, currentIndex + searchTerm.length),
    );
    // Update the lastIndex to after the match
    lastIndex = currentIndex + searchTerm.length;
    // Find the next match
    currentIndex = resultText
      .toLowerCase()
      .indexOf(lowerCaseSearchTerm, lastIndex);
  }
  // Add the remaining text after the last match
  parts.push(resultText.slice(lastIndex));

  return (
    <>
      {parts.map(part => {
        const uniqueId = part + uuidv4(); // UUID necessary for multiple components in view
        return part.toLowerCase() === lowerCaseSearchTerm ? (
          <span key={uniqueId} className="fw-700">
            {part}
          </span>
        ) : (
          part
        );
      })}
    </>
  );
};

export default HighlightSearchTerm;
