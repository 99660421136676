import React from 'react';

class BasketNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text,
      focussed: false,
      inputID: 0,
      inputName: 0,
    };
  }
  handleInput = e => this.setState({ text: e.target.value });
  handleUnfocus = e => this.setState({ focussed: false });
  // NOTE following relies on `onMouseDown`/`onTouchStart` rather than `onClick`
  // `onClick` events fire *after* `blur` events. If `onClick` were to be used,
  // when the input was unfocussed, `blur` would switch the state to
  // `unfocussed`, then `toggleFocus` would switch it back to `focussed`:
  toggleFocus = e => this.setState({ focussed: !this.state.focussed });

  render() {
    let action = {};
    if (this.state.focussed) {
      action = {
        label: 'Editing note',
        state: 'editing',
        isDisabled: false,
        buttonContent: 'check',
      };
    } else if (this.state.text === '' || this.state.text === null) {
      action = {
        label: 'Add note',
        state: 'empty',
        isDisabled: true,
        buttonContent: 'plus',
      };
    } else {
      action = {
        label: 'Edit note',
        state: 'populated',
        isDisabled: true,
        buttonContent: 'pencil',
      };
    }

    return (
      <div className={`note note-${action.state}`}>
        <input
          className={`note-input note-input-${action.state}`}
          readOnly={action.isDisabled}
          id={this.props.inputID}
          name={this.props.inputName}
          onBlur={this.handleUnfocus}
          onChange={this.handleInput}
          type="text"
          value={this.state.text || ''}
        />
        <label
          className={`note-label  note-label-${action.state}`}
          htmlFor={this.props.inputID}
          onMouseDown={this.toggleFocus}
          onTouchStart={this.toggleFocus}>
          <span className={`note-ctrl`} role="button">
            <i className={`fa fa-${action.buttonContent}`}></i>
          </span>
          <span className={`note-label-text note-label-text-${action.state}`}>
            {action.label}
          </span>
        </label>
      </div>
    );
  }
}

export default BasketNote;
