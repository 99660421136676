import { create } from 'zustand';

const basketStore = (set, get) => ({
  basket: {},
  userBasketLines: [],
  basketGoodsTotal: '',
  basketUpdateMessage: '',
  steps: ['DELIVERY_ADDRESS', 'ADDITIONAL_INFORMATION', 'CONFIRM'],
  currentStep: 'DELIVERY_ADDRESS',
  deliveryPanelProps: {},
  deliveryOption: '',
  purchaseOrder: '',
  additionalInfo: '',
  orderComplete: '',
  whiteGoodsWarning: false,
  addressBook: [],
  selectedDeliveryAddress: {},
  accountId: '',
  deliveryAddressIsInvoiceAddress: false,

  setState: (key, value) => set(() => ({ [key]: value })),

  setCurrentBasket: basket => get().setState('basket', basket),

  setBasketGoodsTotal: basketGoodsTotal =>
    get().setState('basketGoodsTotal', basketGoodsTotal),

  addCurrentBasketLines: currentProducts =>
    get().setState('userBasketLines', currentProducts),

  setOrderComplete: orderComplete =>
    get().setState('orderComplete', orderComplete),

  setBasketUpdateMessage: message =>
    get().setState('basketUpdateMessage', message),

  clearBasket: () => get().setState('basket', {}),

  setDeliveryPanelProps: data => get().setState('deliveryPanelProps', data),

  setDeliveryAddress: address =>
    get().setState('selectedDeliveryAddress', address),

  setAccountId: id => get().setState('accountId', id),

  setCurrentStep: step => get().setState('currentStep', step),

  setDeliveryOption: deliveryOption =>
    get().setState('deliveryOption', deliveryOption),

  setPurchaseOrder: purchaseOrder =>
    get().setState('purchaseOrder', purchaseOrder),

  setAdditionalInfo: additionalInfo =>
    get().setState('additionalInfo', additionalInfo),

  setDisplayWhiteGoodsWarning: whiteGoodsWarning =>
    get().setState('whiteGoodsWarning', whiteGoodsWarning),

  setDeliveryAddressIsInvoiceAddress: deliveryAddressIsInvoiceAddress =>
    get().setState(
      'deliveryAddressIsInvoiceAddress',
      deliveryAddressIsInvoiceAddress,
    ),

  resetDeliveryAddresses: () =>
    set(state => ({
      basket: {
        ...state.basket, // Keep the rest of the basket properties unchanged
        delivery_address1: '',
        delivery_address2: '',
        delivery_address3: '',
        delivery_address4: '',
        delivery_address5: '',
        delivery_postcode: '',
      },
    })),

  addBasketLine: productToAdd => {
    const {
      quantity: newQuantity,
      product: { id: productId },
      goods: newGoods,
      unit: newUnit,
    } = productToAdd;

    const productIdToString = productId.toString();
    const products = get().userBasketLines;

    const duplicateProduct = products.find(
      ({ product: { id: itemId } }) => itemId === productIdToString,
    );

    if (duplicateProduct) {
      duplicateProduct.quantity = newQuantity;
      duplicateProduct.goods = newGoods;
      duplicateProduct.unit = newUnit;

      const updatedProducts = products.map(item =>
        item.product.id === duplicateProduct.product.id
          ? duplicateProduct
          : item,
      );

      get().setState('userBasketLines', updatedProducts);
      get().setState('basketUpdateMessage', '');
      return;
    }

    get().setState('userBasketLines', [...products, productToAdd]);
    get().setState('basketUpdateMessage', '');
  },

  removeBasketLine: id => {
    const products = get().userBasketLines;
    const updatedProducts = products.filter(
      ({ line_id: lineId }) => lineId !== id,
    );

    set(() => ({
      userBasketLines: updatedProducts,
    }));
  },
  setAddressBook: addressBook => {
    set(() => ({
      addressBook,
    }));
  },
  addToAddressBook: address => {
    const addresses = get().addressBook;
    const addressExists = addresses.some(item => item.id === address.id);
    let updatedAddresses;

    if (addressExists) {
      updatedAddresses = addresses.filter(p => p.id !== address.id);
    } else {
      updatedAddresses = addresses;
    }

    set(() => ({
      addressBook: [address, ...updatedAddresses],
    }));
  },
  removeFromAddressBook: id => {
    const addresses = get().addressBook;
    const updatedAddressBook = addresses.filter(address => address.id !== id);

    set(() => ({
      addressBook: updatedAddressBook,
    }));
  },
});

const useBasketStore = create(basketStore);

export default useBasketStore;
