import { useReducer, useEffect } from 'react';

import usePricingStore from 'stores/pricingStore/usePricingStore';
import useSearchResults from 'hooks/useSearchResults';
import usePricing from 'hooks/usePricing';
import {
  autoCompleteReducer,
  initialState,
} from 'reducers/autoCompleteReducer';

const useAutoComplete = ({
  isProductList,
  startSearch,
  searchUrl,
  propsOverlay,
  propsSearchPlaceholder,
}) => {
  const { response, setConfig: setSearchResultsConfig } = useSearchResults();
  const { response: pricingResponse, setConfig: setPricingConfig } =
    usePricing();
  const { setAutocompleteProductPrices, setProductPricingError } =
    usePricingStore(state => ({
      setAutocompleteProductPrices: state.setAutocompleteProductPrices,
      setProductPricingError: state.setProductPricingError,
    }));

  const [state, dispatch] = useReducer(autoCompleteReducer, initialState);
  const { loading, results, categories, searchTerm, typingTimeout } = state;

  useEffect(() => {
    if (response.result) {
      const { success, data } = response.result;
      if (success) {
        const { products: productsNew, categories: categoriesNew } = data;
        if (productsNew) {
          dispatch({
            type: 'SET_SEARCH_RESULTS',
            payload: {
              loading: false,
              results: productsNew,
              categories: categoriesNew.length ? categoriesNew : [],
            },
          });

          const productsForPricing = {
            products: productsNew.map(p => p.id).join(','),
          };

          setPricingConfig({
            method: 'GET',
            url: `/prices`,
            isProductList,
            params: productsForPricing,
          });
        } else {
          dispatch({
            type: 'SET_SEARCH_RESULTS',
            payload: {
              loading: false,
              results: [],
              categories: [],
            },
          });
        }
      } else {
        dispatch({
          type: 'SET_SEARCH_RESULTS',
          payload: {
            loading: false,
            results: [],
            categories: [],
          },
        });
      }
    }
  }, [response.result]);

  useEffect(() => {
    if (!pricingResponse.result) return;
    if (pricingResponse.result) {
      const { success, data: pricingData } = pricingResponse.result;
      if (success) {
        setAutocompleteProductPrices(pricingData.prices);
        // pushProductImpressions(pricingData.prices);
      } else {
        const {
          data: { error: errorMessage, error_title: errorTitle },
        } = pricingResponse.result;

        const pricingError = { errorMessage, errorTitle };
        setProductPricingError(pricingError);
      }
    }
  }, [pricingResponse.result]);

  const search = (term = searchTerm) => {
    dispatch({ type: 'SET_CATEGORIES', payload: [] });
    setSearchResultsConfig({
      method: 'GET',
      url: searchUrl,
      params: { q: term },
    });
  };

  useEffect(() => {
    if (searchTerm.length >= startSearch) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      dispatch({ type: 'SET_LOADING', payload: true });
      dispatch({
        type: 'SET_TYPING_TIMEOUT',
        payload: setTimeout(() => {
          search();
        }, 250),
      });
    } else {
      dispatch({
        type: 'SET_SEARCH_RESULTS',
        payload: {
          loading: false,
          results: [],
          categories: [],
        },
      });

      dispatch({ type: 'SET_TYPING_TIMEOUT', payload: 0 });
    }
  }, [searchTerm]);

  const closeSearch = () => {
    const searchInput = document.querySelector('.search-autocomplete__input');
    dispatch({ type: 'SET_SEARCH_TERM', payload: '' });
    dispatch({ type: 'SET_RESULTS', payload: [] });
    searchInput.value = '';
  };

  const handleSubmit = () => {
    if (searchTerm.length >= startSearch) {
      const term = encodeURIComponent(searchTerm);
      window.location.href = `/search?q=${term}`;
    }
  };

  return {
    loading,
    results,
    categories,
    searchTerm,
    setSearchTerm: term => dispatch({ type: 'SET_SEARCH_TERM', payload: term }),
    searchPlaceholder: propsSearchPlaceholder || 'Search',
    closeSearch,
    handleSubmit,
    propsOverlay,
  };
};

export default useAutoComplete;
