import { useState, useEffect } from 'react';
import axios from 'packs/axios';

const handleError = error => {
  if (error) {
    return {
      success: false,
      error,
    };
  }
  return {
    success: false,
    data: { message: 'Network error' },
    status: 500,
    statusText: 'Network error',
  };
};

const useGET = (url, options = {}) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(url, options);

        if (response.status === 200) {
          setError(undefined);
          setData(response.data);
        }
      } catch (err) {
        setError(handleError(err.response));
      } finally {
        setLoading(false);
      }
    };

    if (url) {
      fetchData();
    }
  }, [url]);

  return { loading, data, error };
};

const usePOST = (url, body, options = {}) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${url}`, body, options);

        if (response.status === 200) {
          setData(response.data);
          setError(undefined);
        }
      } catch (err) {
        setError(handleError(err.response));
      } finally {
        setLoading(false);
      }
    };

    if (url) {
      fetchData();
    }
  }, [url]);

  return { loading, data, error };
};

const useHTTP = requestConfig => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { url } = requestConfig;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios(requestConfig);

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (err) {
        setError(handleError(err.response));
      } finally {
        setLoading(false);
      }
    };
    if (url) {
      fetchData();
    }
  }, [url]);

  return { loading, data, error };
};

export { useGET, usePOST, useHTTP };
