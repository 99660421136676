import { useAsync } from 'react-async-hook';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useState } from 'react';
import axios from 'packs/axios';

const CompareProducts = async config => {
  if (config === null) return undefined;

  const { url, method, params } = config;
  const getResults = await axios(url, {
    method,
    params,
  })
    .then(({ data }) => ({
      success: true,
      data,
    }))
    .catch(({ response }) => {
      if (response) {
        return { success: false, data: response };
      }
      return {
        success: false,
        data: {
          data: { message: 'Network error' },
          status: 500,
          statusText: 'Network error',
        },
      };
    });

  return getResults;
};

const useCompareProducts = () => {
  const [config, setConfig] = useState(null);

  const debounceCompareProducts = useConstant(() =>
    AwesomeDebouncePromise(CompareProducts, 300),
  );

  const response = useAsync(
    async () => debounceCompareProducts(config),
    [config],
  );

  return {
    response,
    setConfig,
  };
};

export default useCompareProducts;
