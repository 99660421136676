import React from 'react';
import {
  successGreen,
  orange10,
} from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

import { InStockIcon, OutOfStockIcon } from '../shared/CommonSvgs';
import LoadingSpinnerComponent from '../shared/LoadingSpinnerComponent';

const ProductShowPrice = ({
  productShowPrice,
  strictlyNett,
  compareProduct,
}) => (
  <>
    <div>
      {!productShowPrice && (
        <LoadingSpinnerComponent className="fl fl-1 jc-fe mt-23 mb-22" />
      )}
      <h2
        className={`product_price pricing-box-price mr-8 
        ${compareProduct ? 'fs-24' : 'fs-24 m-fs-32'} 
        ${strictlyNett ? 'mt-16 mb-0' : 'mtb-16'}`}>
        {productShowPrice}
      </h2>
      {strictlyNett && (
        <p className="pricing-box-rrp nm fs-14 m-fs-18">
          <span>Strictly Nett</span>
        </p>
      )}
    </div>
  </>
);

const ProductPrice = ({ price, isProductShow, stock }) => (
  <div className="current-price fl fl-1 ai-fe fl-col">
    {!price && <LoadingSpinnerComponent className="fl fl-1 jc-fe" />}
    {price && !isProductShow && (
      <>
        <div className="current-price__price ff--rc td-none mb-5">{price}</div>
        <div className="current-price__stock fl fd-col fs-14">
          {stock > 0 && price ? (
            <>
              <InStockIcon
                className="mr-4"
                width="20"
                height="20"
                fillColor={successGreen}
              />
              <span className="current-price__stock__value ff--rc fw-500 td-none">
                {+stock >= 1000 ? '1000+' : stock}
              </span>
            </>
          ) : (
            <>
              <OutOfStockIcon
                className="mr-4"
                width="20"
                height="20"
                fillColor={orange10}
              />
              <span className="current-price__stock__value ff--rc fw-500 td-none">
                Out of stock
              </span>
            </>
          )}
        </div>
      </>
    )}
  </div>
);

const PriceCalc = ({
  showPriceBox,
  price,
  stock,
  isProductShow,
  strictlyNett,
  compareProduct,
}) => {
  if (!showPriceBox) {
    return null;
  }

  if (isProductShow) {
    return (
      <ProductShowPrice
        productShowPrice={price}
        strictlyNett={strictlyNett}
        compareProduct={compareProduct}
      />
    );
  }

  return (
    <ProductPrice
      showPriceBox={showPriceBox}
      price={price}
      stock={stock}
      isProductShow={isProductShow}
    />
  );
};

export { PriceCalc as default };
