import React, { useEffect } from 'react';

const OrderComplete = ({ url }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // Additional validation for the URL
      const isValidUrl = inputUrl => {
        try {
          return inputUrl.includes('/account/orders/');
        } catch (e) {
          return false;
        }
      };

      if (isValidUrl(url)) {
        const sanitizedUrl = encodeURI(url);
        window.location.assign(sanitizedUrl);
      } else {
        window.location.assign('/account/orders');
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [url]);

  return (
    <div className="order-complete">
      <div className="order-content">
        <h1>Completing your Order...</h1>
        <div className="loader" />
      </div>
    </div>
  );
};

export default OrderComplete;
