import React from 'react';
import { CheckBoxCheckedIcon, CheckBoxIcon } from '../../../shared/CommonSvgs';

const ProductAggregateOption = ({
  option,
  toggleAggregate,
  agg_type: aggType,
}) => {
  const { selected, key, value } = option;

  const aggregateStatus =
    selected === true ? (
      <CheckBoxCheckedIcon className="mr-8" />
    ) : (
      <CheckBoxIcon className="mr-8" />
    );

  if (key === '') {
    return null;
  }

  return (
    <li className="aggregate fl ai-c">
      <button
        onClick={() => {
          toggleAggregate(aggType, value, true, true);
        }}
        type="button"
        className="fl fl-1 ai-s m-0 b-none bg-none">
        {aggregateStatus}
        <span className="aggregate__title fl fl-1 ai-c jc-fs">{key}&nbsp;</span>
      </button>
    </li>
  );
};

export default ProductAggregateOption;
