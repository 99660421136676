import Cookies from 'js-cookie';

const hamlPriceSwitch = () => {
  const cookieValue = Cookies.get('show_pricing');

  if (cookieValue && !JSON.parse(cookieValue)) {
    // Hide Price on Product Show page
    document.querySelectorAll('.product-price').forEach(element => {
      element.classList.add('d-none');
    });

    // Hide Price Details on Basket Show
    document
      .querySelectorAll(
        '.item-price__unit-price, .bulk-discount-reduction, .order-summary .row',
      )
      .forEach(element => {
        element.classList.add('d-none');
      });
  }
};

export default hamlPriceSwitch;
