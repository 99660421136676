import React from 'react';
import styled from 'styled-components';
import { Button } from '@cityelectricalfactors/ui-components';
import { AutocompleteCloseIcon } from './CommonSvgs';
import { grey70 } from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const GlobalModalContent = ({ closeModal, title, content }) => (
  <>
    <div className="fl ai-c">
      <h2 className="fl ai-c fw-700 ff-rc m-0 fs-24 jc-c m-jc-fs plr-24 pt-18 pb-20">
        {title}
      </h2>
      <CancelButton
        className="fl ai-c jc-c ml-auto mr-16"
        aria-label="Cancel"
        type="button"
        handleClick={closeModal}
        label="Cancel">
        <AutocompleteCloseIcon
          className="search-autocomplete__icon search-autocomplete__close"
          width="32"
          height="32"
          viewBox="0 0 20 20"
          fillColor={grey70}
        />
      </CancelButton>
    </div>

    <ModalContent className="fl plr-24">
      <p className="m-0 fs-16">{content}</p>
    </ModalContent>
  </>
);

const ModalContent = styled.div`
  flex-direction: column;
  margin: 0;
`;

const CancelButton = styled(Button)`
  background: none;
  font-weight: 500;
  border: 0;
  padding: 0;
  width: 40px;
  height: 40px;
`;

export default GlobalModalContent;
