import React from 'react';
import useRemoveFavourite from 'hooks/useDeleteUserFavourite';
import useUserFavouritesStore from 'stores/userFavouritesStore/useUserFavouritesStore';
import { AutocompleteCloseIcon } from 'components/shared/CommonSvgs';
import { grey70 } from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const UserFavouriteProduct = ({
  product: { id, url, description, catalogue, imgUrl },
}) => {
  const removeFavouriteProduct = useUserFavouritesStore(
    state => state.removeFavouriteProduct,
  );

  const onSuccess = () => {
    removeFavouriteProduct(id);
  };

  const { handleRemove } = useRemoveFavourite(id, onSuccess);

  return (
    <div className="user-favourite__product fl pl-12 ai-c mb-16 td-none">
      <div className="user-favourite__product-image mr-16">
        {imgUrl !== '' && (
          <a href={url} aria-label="Product">
            <img src={imgUrl} alt="" width="60" height="60" />
          </a>
        )}
      </div>

      <div className="dropdown__product-content">
        <h3 className="fs-18 ff--rc td-none mt-0 mb-0">
          <a href={url} className="dropdown__product-title">
            {description}
          </a>
        </h3>
        <div>
          <a href={url} className="dropdown__product-code">
            <span className="fs-14">
              Part Code: <strong className="ml-6">{catalogue}</strong>
            </span>
          </a>
        </div>
      </div>
      <div className="user-favourite__product-remove fl ai-c jc-c">
        <button
          type="button"
          aria-label="Remove Product"
          onClick={handleRemove}>
          <AutocompleteCloseIcon
            className="search-autocomplete__icon search-autocomplete__close"
            width="14"
            height="14"
            fillColor={grey70}
          />
        </button>
      </div>
    </div>
  );
};

export default UserFavouriteProduct;
