import React, { useState, useEffect } from 'react';
import { addressFormatter } from 'helpers/utils';
import DeleteItem from 'components/UI/DeleteItem';
import useBasketStore from 'stores/basketStore/useBasketStore';

const Address = ({
  setDeliveryAddressIsInvoiceAddress,
  showGlobalError,
  address,
  address: { id },
}) => {
  const [closeModal, setCloseModal] = useState(false);
  const [checked, setChecked] = useState(false);

  const { selectedDeliveryAddress, setDeliveryAddress, removeFromAddressBook } =
    useBasketStore(state => ({
      selectedDeliveryAddress: state.selectedDeliveryAddress,
      setDeliveryAddress: state.setDeliveryAddress,
      removeFromAddressBook: state.removeFromAddressBook,
    }));

  useEffect(() => {
    if (id === selectedDeliveryAddress?.id) {
      setChecked(true);
    }
  }, [selectedDeliveryAddress]);

  const handleDeleteSuccess = ({ address_id: addressId }) => {
    setCloseModal(true);
    removeFromAddressBook(addressId);
    document.body.style.overflow = 'auto';
  };

  const handleDeleteError = ({ error }) => {
    const message = error?.data?.message;
    if (message) {
      setCloseModal(true);
      showGlobalError(message);
      document.body.style.overflow = 'auto';
    }
  };

  return (
    <div
      className={`col-12 col-lg-6 pap-grid-col pap-grid-col-animated
    checkout__address br-8 b-full b-colour--grey plr-16 
    ptb-16 fl fl-col m-fl-row m-ai-c jc-sb fs-16 ta-l ff--r`}>
      <div className="form-group width-100">
        <div className="checkout__address-radio">
          <label
            className="form-control with-height"
            htmlFor={`address-book-radio-${id}`}>
            <input
              id={`address-book-radio-${id}`}
              name="radio"
              type="radio"
              onChange={() => {
                setDeliveryAddress(address);
                setDeliveryAddressIsInvoiceAddress(false);
              }}
              checked={checked}
            />
            <div className="fl fl-col m-fl-row jc-sb">
              <span className="checkout__address-with-name fl m-0 ai-c fs-16">
                {addressFormatter(address)}
              </span>
            </div>
          </label>
        </div>
      </div>
      <div className="checkout__address-controls fl mt-20 m-mt-0">
        <a
          href={`/account/account_addresses/${id}/edit`}
          className="checkout-address-controls__edit">
          Edit
        </a>
        <DeleteItem
          closeModal={closeModal}
          url={`/account/account_addresses/${id}`}
          className="checkout-address-controls__delete bg-none fs-14 td-u"
          mainButtonText="Delete"
          modalTitle="Delete Address"
          modalContent="Are you sure you want to delete this address?"
          modalButtonContent="Delete"
          onSuccess={handleDeleteSuccess}
          onError={handleDeleteError}
        />
      </div>
    </div>
  );
};

export default Address;
