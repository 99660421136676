import React from 'react';

const SkeletonLoadingProduct = () => (
  <div className="autocomplete__product product mb-12 skeleton">
    <div className="autocomplete__product-image product-image">
      <div className="skeleton-thumbnail skeleton-animation" />
    </div>
    <div className="autocomplete__product-content product-content">
      <div className="autocomplete__product-title skeleton-title skeleton-animation" />
      <div className="skeleton-text skeleton-animation" />
      <div className="skeleton-text skeleton-animation" />
    </div>
    <div className="autocomplete__product-price product-price ml-8">
      <div className="skeleton-price skeleton-animation" />
    </div>
  </div>
);

export default SkeletonLoadingProduct;
