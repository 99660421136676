import React, { useEffect, useState } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import useBasketStore from 'stores/basketStore/useBasketStore';
import { useHTTP } from 'hooks/useRequest';
import LoadingSpinnerComponent from 'components/shared/LoadingSpinnerComponent';
import { pushToDataLayer } from 'components/shared/PushToDatalayer';

const NextStep = ({ nextStepData, continueCondition }) => {
  const [loading, setLoading] = useState(false);
  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: null,
  });

  const { setCurrentStep, setCurrentBasket } = useBasketStore(state => ({
    setCurrentStep: state.setCurrentStep,
    setCurrentBasket: state.setCurrentBasket,
  }));

  const { data } = useHTTP(requestConfig);

  const handleUpdate = () => {
    setRequestConfig({
      url: '/checkout',
      method: 'put',
      data: nextStepData,
    });
    setLoading(true);
  };

  useEffect(() => {
    if (data) {
      const { basket } = data;
      setCurrentBasket(basket);
      setLoading(false);
      setCurrentStep(basket.step.toUpperCase());

      const {
        gtmData: { itemData, event, additionalData },
      } = nextStepData;
      pushToDataLayer(event, itemData, { ...additionalData });
    }
  }, [data]);

  return (
    <div className="checkout__panel-next-step col-12 fl ai-c m-jc-fe">
      <Button
        disabled={!continueCondition}
        className="btn btn-blue pap-btn--xl br-4 pap-btn--high"
        handleClick={() => handleUpdate()}>
        {loading ? (
          <LoadingSpinnerComponent className="fl fl-1 jc-c" />
        ) : (
          'Save & Continue'
        )}
      </Button>
    </div>
  );
};

export default NextStep;
