import React from 'react';
import { OutOfStockIcon } from 'components/shared/CommonSvgs';
import { orange10 } from '../../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const InvalidAddress = () => (
  <div className="fl mtb-12 p-6 fs-14 b-full b-colour-orange bgc--orange br-2">
    <OutOfStockIcon
      className="mr-4"
      width="20"
      height="20"
      fillColor={orange10}
    />
    <p className="fl fl-col nm np">
      <span className="fw-700 fs-16 mr-4">Invalid Postcode Entered</span>

      <span>Please enter a valid UK postcode.</span>
    </p>
  </div>
);

export default InvalidAddress;
