import React from 'react';

import SpecialLoadingState from './special.loading.state';
import SpecialOfferContent from './special.content';

class SpecialOffers extends React.Component {
  constructor(props) {
    super(props);
    let display_prices = props.signed_in;
    if (props.signed_in) {
      display_prices = 'true';
      _.each(document.cookie.split(`;`), c => {
        let formatted_cookie = c.replace(/ /g, '');
        if (_.startsWith(formatted_cookie, `hide_prices`)) {
          display_prices = formatted_cookie.split(`=`)[1];
        }
      });
    }
    this.state = {
      offers: [],
      original_offers: [],
      config: {
        url: '/general_offers.json',
        numberOfOffers: 8,
        display_prices: display_prices,
      },
    };
  }
  componentDidMount() {
    this.renderLoadingOffers();
  }
  renderLoadingOffers() {
    let offers = [];
    for (let i = 0, len = this.state.config.numberOfOffers; i < len; i++) {
      offers.push(<SpecialLoadingState key={i} offer={i + 1} />);
    }
    this.setState({ offers: offers });
    this.getOffers();
  }
  getOffers() {
    let new_offers = [];
    let _this = this;

    $.get(this.state.config.url, offers => {
      if (offers.length >= 1) {
        _.each(
          _.take(_.shuffle(offers), _this.state.config.numberOfOffers),
          function (offer, index) {
            new_offers.push(
              <SpecialOfferContent
                display_pricing={_this.state.config.display_prices}
                key={index}
                data={offer}
                offer={index + 1}
              />,
            );
          },
        );
        this.setState({ offers: new_offers });
        this.setState({ original_offers: new_offers });
      } else {
        // Not enough offers, remove component
        ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this).parentNode);
      }
    });
  }
  render() {
    return (
      <section className="offers offers-general">
        <header className="in-page-header">
          <h1
            className="offers-title offers-general-title"
            style={{ fontWeight: 'bold' }}>
            Special Offers
          </h1>
        </header>
        <div className="container">
          <div className="row">{this.state.offers}</div>
        </div>
      </section>
    );
  }
}

export default SpecialOffers;
