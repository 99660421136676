import { useState, useEffect } from 'react';
import { useHTTP } from 'hooks/useRequest';

/**
 * Custom hook to handle deletion of an item with an HTTP DELETE request.
 *
 * @param {string} url - The URL to send the DELETE request to.
 * @param {function} onSuccess - Callback function to execute on successful deletion.
 * @param {function} onError - Callback function to execute on error.
 * @returns {Object} An object containing the handleRemove function to initiate the delete action.
 */
const useDelete = (url, onSuccess, onError) => {
  const [removeItem, setRemoveItem] = useState(false);

  const requestConfig = {
    url: removeItem ? url : null,
    method: 'delete',
    headers: {
      Accept: 'application/json',
    },
  };

  const { error, data } = useHTTP(requestConfig);

  useEffect(() => {
    if (removeItem) {
      if (error) {
        setRemoveItem(false);
        if (onError) {
          onError(error);
        }
      } else if (data) {
        setRemoveItem(false);
        if (onSuccess) {
          onSuccess(data);
        }
      }
    }
  }, [removeItem, data, error, onSuccess, onError]);

  /**
   * Initiates the delete action by setting removeItem to true.
   */
  const handleRemove = () => {
    setRemoveItem(true);
  };

  return { handleRemove };
};

export default useDelete;
