import React, { useState, useEffect } from 'react';
import { useHTTP } from 'hooks/useRequest';
import useUserFavouritesStore from 'stores/userFavouritesStore/useUserFavouritesStore';
import useToastStore from 'stores/useToastStore';
import {
  FavouriteIcon,
  FavouriteIconFull,
  WhiteCheck,
} from 'components/shared/CommonSvgs';

import { successGreen } from '../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const AddToFavourites = ({ product, url, hideIcon }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [requestUrl, setRequestUrl] = useState(null);
  const [actionType, setActionType] = useState(null);

  const { addToast } = useToastStore();
  const {
    addFavouriteProduct,
    removeFavouriteProduct,
    userFavouritesProducts,
  } = useUserFavouritesStore(state => ({
    addFavouriteProduct: state.addFavouriteProduct,
    removeFavouriteProduct: state.removeFavouriteProduct,
    userFavouritesProducts: state.userFavouritesProducts,
  }));

  const productIsFavourite = userFavouritesProducts.some(
    item => item.id === product.id,
  );

  const { error, data } = useHTTP({
    url: requestUrl,
    method: hideIcon ? 'post' : 'get',
    headers: {
      Accept: 'application/json',
    },
  });

  useEffect(() => {
    if (error) {
      addToast('Error processing request.', null, 'decline');
      setIsProcessing(false);
    }

    if (data) {
      if (actionType === 'add') {
        addToast('Product added to Favourites.');
      } else if (actionType === 'remove') {
        addToast('Product removed from Favourites.');
      }
      setIsProcessing(false);
    }
  }, [data, error, addToast, actionType]);

  const handleClick = () => {
    if (isProcessing) return;

    setIsProcessing(true);

    if (productIsFavourite) {
      setActionType('remove');
      setRequestUrl(`/catalogue/products/${product.id}/remove_from_favourite`);
      removeFavouriteProduct(product.id);
    } else {
      setActionType('add');
      setRequestUrl(url);
      addFavouriteProduct(product);
    }
  };

  const cssClass = () => {
    if (hideIcon) {
      return 'basket-actions__action bg-none b-none ff-body c--black-blue td-u plr-0 m-plr-6';
    }

    // Note the removal of unmatched single quotes and handling whitespaces properly
    return `user-favourites user-favourites__add-to-favourites 
      ${productIsFavourite ? 'user-favourites__product-added' : ''}
      fl ai-c jc-c br-100 box-shadow-on-hover fw-400 td-none`;
  };

  return hideIcon && productIsFavourite ? (
    <div className="fl ai-c bgc--green c--green p-6">
      <WhiteCheck
        fillColor={successGreen}
        width="15"
        height="15"
        viewBox="0 0 10 7"
        className="mr-8"
      />
      <p className="m-0">Saved for later</p>
    </div>
  ) : (
    <button
      type="button"
      className={cssClass()}
      aria-label="Add to User Favourites"
      onClick={handleClick}
      disabled={isProcessing}>
      {hideIcon ? (
        'Save For Later'
      ) : (
        <>
          {productIsFavourite ? (
            <FavouriteIconFull width="18" height="16" viewBox="0 0 18 16" />
          ) : (
            <FavouriteIcon viewBox="0 0 20 20" />
          )}
        </>
      )}
    </button>
  );
};

export default AddToFavourites;
