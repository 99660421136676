import React from 'react';
import {
  AccountIcon,
  BasketIcon,
  DashboardIcon,
  FavouriteIcon,
  ContactsIcon,
  InvoiceIcon,
  OrdersIcon,
  ReturnsIcon,
} from 'components/shared/CommonSvgs';

export const Links = [
  { url: '/account', icon: <DashboardIcon />, text: 'Account Overview' },
  {
    url: '/account/account_addresses',
    icon: <ContactsIcon />,
    text: 'Address Book',
  },
  { url: '/account/invoices', icon: <InvoiceIcon />, text: 'Invoices' },
  { url: '/account/orders', icon: <OrdersIcon />, text: 'Orders' },
  { url: '/account/returns', icon: <ReturnsIcon />, text: 'Returns' },
  {
    url: '/account/favourites',
    icon: <FavouriteIcon />,
    text: 'Saved For Later',
  },
  { url: '/account/baskets', icon: <BasketIcon />, text: 'Saved Baskets' },
  { url: '/account/users', icon: <AccountIcon />, text: 'Users' },
];
