import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import { useHTTP } from 'hooks/useRequest';
import { createItemData } from 'utils/createItemData';
import useBasketStore from 'stores/basketStore/useBasketStore';
import Delivery from './panels/Delivery';
import DeliveryOptions from './panels/DeliveryOptions';
import Confirm from './panels/Confirm';
import OrderComplete from './OrderComplete';

const Checkout = ({
  allowedToDeliverAnywhere,
  accountInvoiceAddress,
  addresses,
  currentBasket,
}) => {
  const {
    setCurrentStep,
    currentStep,
    setCurrentBasket,
    orderComplete,
    setAddressBook,
    addressBook,
    resetDeliveryAddresses,
    basket,
  } = useBasketStore(state => ({
    resetDeliveryAddresses: state.resetDeliveryAddresses,
    setCurrentStep: state.setCurrentStep,
    currentStep: state.currentStep,
    setCurrentBasket: state.setCurrentBasket,
    orderComplete: state.orderComplete,
    setAddressBook: state.setAddressBook,
    addressBook: state.addressBook,
    basket: state.basket,
  }));

  const tabRef1 = useRef(null);
  const tabRef2 = useRef(null);
  const tabRef3 = useRef(null);

  const [itemData, setItemData] = useState([]);

  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: null,
  });

  const { data } = useHTTP(requestConfig);

  const handleEdit = step => {
    setRequestConfig({
      url: '/checkout',
      method: 'put',
      data: { basket: { step: step.toLowerCase() } },
    });
  };

  useEffect(() => {
    if (data) {
      const { basket: updatedBasket } = data;
      setCurrentBasket(updatedBasket);
      setCurrentStep(updatedBasket.step.toUpperCase());
      setRequestConfig({
        url: null,
        method: null,
      });
    }
  }, [data]);

  useEffect(() => {
    setRequestConfig({
      url: '/checkout',
      method: 'put',
      data: { basket: { step: 'delivery_address' } },
    });

    setCurrentBasket(currentBasket);
    setAddressBook(addresses);
    resetDeliveryAddresses();
  }, []);

  const deliveryPanelProps = {
    allowedToDeliverAnywhere,
    accountInvoiceAddress,
    addressBook,
    itemData,
  };

  useEffect(() => {
    const newItemData = basket?.lines?.map(
      (
        {
          product: {
            id,
            catalogue,
            description_short: description,
            brand,
            categories,
            rrp,
          },
          unit,
          quantity,
        },
        index,
      ) =>
        createItemData({
          id,
          catalogue,
          description,
          brand,
          categories,
          rrp,
          unit,
          quantity,
          index,
          itemListId: 'basket_page',
          itemListName: 'Basket Page',
        }),
    );

    setItemData(newItemData);
  }, [basket]);

  const panels = [
    {
      step: 'DELIVERY_ADDRESS',
      title: 'Delivery Address',
      component: <Delivery {...deliveryPanelProps} />,
      tabRef: tabRef1,
    },
    {
      step: 'ADDITIONAL_INFORMATION',
      title: 'Delivery Options',
      component: <DeliveryOptions itemData={itemData} />,
      tabRef: tabRef2,
    },
    {
      step: 'CONFIRM',
      title: 'Confirm',
      component: (
        <Confirm invoiceAddress={accountInvoiceAddress} itemData={itemData} />
      ),
      tabRef: tabRef3,
    },
  ];

  const renderPanel = panelStep => {
    const panel = panels.find(p => p.step === panelStep);
    if (panel && panel.tabRef.current) {
      panel.tabRef.current.scrollIntoView({ behavior: 'smooth' });
      return panel.component;
    }
    return null;
  };

  const getCurrentStepIndex = () =>
    panels.findIndex(panel => panel.step === currentStep);

  useEffect(() => {
    const activePanel = panels.find(p => p.step === currentStep);
    if (activePanel && activePanel.tabRef.current) {
      // Scroll to the active tab when it becomes active
      activePanel.tabRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentStep]);

  return orderComplete.complete ? (
    <OrderComplete url={orderComplete.url} />
  ) : (
    <div>
      {panels.map(({ step, title, tabRef }, index) => (
        <div className="checkout__panel" key={step} id={step} ref={tabRef}>
          <h2 className="col-12 fl ai-c m-0">
            <span
              className="checkout__step br-100 b-full b-colour--grey20 
                        mr-10 fl ai-c jc-c fs-16">
              {index + 1}
            </span>
            {title}
            {getCurrentStepIndex() > index && (
              <Button
                className="checkout__step-edit br-8 plr-8"
                handleClick={() => handleEdit(step)}>
                Edit
              </Button>
            )}
          </h2>
          {currentStep === step && renderPanel(step)}
        </div>
      ))}
    </div>
  );
};

export default Checkout;
