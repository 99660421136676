import {
  SET_MULTIPLES_OF,
  SET_QUANT_LESS_THAN_MIN_ORDER,
  SET_QUANT_MORE_THAN_MAX_QUANT,
  SET_QUANT_CORRECT,
  SET_EMPTY_QUANT,
  SET_INPUT_CHANGE,
  SET_BACKORDER_MODAL,
  SET_PRODUCT_ADDED,
  SET_ADDING_PRODUCT,
} from './addToBasketActionTypes';

export const initialState = {
  count: 1,
  minimumOrder: 0,
  maximumQuantity: 0,
  deliveryMinOrder: 0,
  amount: 0,
  alert: false,
  unauthMessage: '',
  message: '',
  loadingPrice: true,
  price: 0,
  errorTitle: '',
  errorMessage: '',
  lineErrorMessage: '',
  per: '',
  updateDisabled: false,
  spendLimit: 0,
  pricePer: 0,
  showErrorForProduct: false,
  show: false,
  maximumGoodsTotal: false,
  showModal: false,
  insufficientStock: false,
  loading: false,
  basketSuccess: false,
  addProduct: false,
  onBasketPage: false,
};

const addToBasketReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADED_STATE':
      return {
        ...state,
        alert: false,
        errorTitle: '',
        errorMessage: '',
        lineErrorMessage: '',
        count: action.payload.count,
        minimumOrder: action.payload.minimumOrder,
        maximumQuantity: action.payload.maximumQuantity,
        deliveryMinOrder: action.payload.deliveryMinOrder,
        onBasketPage: action.payload.onBasketPage,
      };
    case SET_MULTIPLES_OF:
      return {
        ...state,
        count: action.payload.minOrder,
        alert: true,
        message: 'Sorry you can only order this product in multiples of ',
        amount: action.payload.minOrder,
        show: false,
      };
    case SET_QUANT_LESS_THAN_MIN_ORDER:
      return {
        ...state,
        count: action.payload,
        amount: action.payload,
        message: 'Sorry you can only order a minimum of ',
        alert: true,
        show: false,
      };
    case SET_QUANT_MORE_THAN_MAX_QUANT:
      return {
        ...state,
        count: action.payload,
        alert: true,
        message: 'Sorry you can only order a maximum of ',
        amount: action.payload,
      };
    case SET_EMPTY_QUANT:
      return {
        ...state,
        count: '',
        updateDisabled: true,
      };
    case SET_INPUT_CHANGE:
      return {
        ...state,
        count: action.payload,
        alert: false,
        updateDisabled: false,
      };
    case SET_QUANT_CORRECT:
      return {
        ...state,
        count: action.payload.count,
        message: '',
        alert: false,
        updateDisabled: false,
        maximumGoodsTotal: false,
      };
    case SET_ADDING_PRODUCT:
      return {
        ...state,
        addProduct: action.payload.addProduct,
        loading: action.payload.loading,
      };
    case SET_PRODUCT_ADDED:
      return {
        ...state,
        basketSuccess: action.payload.basketSuccess,
        addProduct: action.payload.addProduct,
        loading: action.payload.loading,
      };
    case SET_BACKORDER_MODAL:
      return {
        ...state,
        showModal: action.payload.showModal,
        insufficientStock: action.payload.insufficientStock,
      };
    case 'SET_COUNT':
      return { ...state, count: action.payload };
    case 'SET_MINIMUM_ORDER':
      return { ...state, minimumOrder: action.payload };
    case 'SET_MAXIMUM_QUANTITY':
      return { ...state, maximumQuantity: action.payload };
    case 'SET_DELIVERY_MIN_ORDER':
      return { ...state, deliveryMinOrder: action.payload };
    case 'SET_AMOUNT':
      return { ...state, amount: action.payload };
    case 'SET_ALERT':
      return { ...state, alert: action.payload };
    case 'SET_UNAUTH_MESSAGE':
      return { ...state, unauthMessage: action.payload };
    case 'SET_MESSAGE':
      return { ...state, message: action.payload };
    case 'SET_LOADING_PRICE':
      return { ...state, loadingPrice: action.payload };
    case 'SET_PRICE':
      return { ...state, price: action.payload };
    case 'SET_ERROR_TITLE':
      return { ...state, errorTitle: action.payload };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'SET_LINE_ERROR_MESSAGE':
      return { ...state, lineErrorMessage: action.payload };
    case 'SET_PER':
      return { ...state, per: action.payload };
    case 'SET_UPDATE_DISABLED':
      return { ...state, updateDisabled: action.payload };
    case 'SET_SPEND_LIMIT':
      return { ...state, spendLimit: action.payload };
    case 'SET_PRICE_PER':
      return { ...state, pricePer: action.payload };
    case 'SET_SHOW_ERROR_FOR_PRODUCT':
      return { ...state, showErrorForProduct: action.payload };
    case 'SET_SHOW':
      return { ...state, show: action.payload };
    case 'SET_MAXIMUM_GOODS_TOTAL':
      return { ...state, maximumGoodsTotal: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_BASKET_SUCCESS':
      return { ...state, basketSuccess: action.payload };
    default:
      return state;
  }
};

export default addToBasketReducer;
