import React from 'react';

/**
 *
 * @param {string} type - input type
 * @param {string} ariaLabel - aria-label for accesibility
 * @param {string} label - label text for input
 * @param {string} name - name attribute
 * @param {string} id - id attribute
 * @param value - input value
 * @param {Function} handleChange - change event handler
 * @param {Function} handleFocus - focus event handler
 * @param {Function} handleBlur - blur event handler
 * @param {string} labelClassName - CSS Classses for <label>
 * @param {string} inputClassName - CSS Classses for <input/>
 * @returns A <label> + <input>
 */
const Input = ({
  type,
  ariaLabel,
  label,
  name,
  id,
  value,
  handleChange,
  handleBlur,
  handleFocus,
  labelClassName,
  inputClassName,
  onKeyDown,
  errorClassName,
  maxLength,
  placeholder,
  required,
  error,
  disabled,
  min,
  textRight,
  ...props
}) => (
  <>
    {label && !textRight && (
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
    )}
    <input
      aria-label={ariaLabel}
      type={type}
      name={name}
      id={id}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      className={inputClassName}
      disabled={disabled}
      min={min}
      maxLength={maxLength}
      onKeyDown={onKeyDown}
      required={required}
      {...props}
    />
    {label && textRight && (
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
    )}
    {error && (
      <p className={`cef-ui-input__error ${errorClassName}`}>{error}</p>
    )}
  </>
);

export default Input;
