import React from 'react';
import useBasketStore from 'stores/basketStore/useBasketStore';
import BasketLine from './BasketLine';

const BasketLines = ({ whiteGoodsMinOrder }) => {
  const {
    basket: { contains_white_goods: containsWhiteGoods, lines },
  } = useBasketStore(state => ({
    basket: state.basket,
  }));

  return (
    <div className="col-12 m-col-9 m-mr-16 height-fc br-8 b-full b-colour--grey fs-14">
      {lines.map(product => (
        <BasketLine
          key={product?.line_id}
          {...product}
          whiteGoodsMinOrder={whiteGoodsMinOrder}
          containsWhiteGoods={containsWhiteGoods}
        />
      ))}
    </div>
  );
};

export default BasketLines;
