const initialState = {
  loading: false,
  results: [],
  categories: [],
  searchTerm: '',
  typingTimeout: 0,
};

const autoCompleteReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCH_RESULTS':
      return {
        ...state,
        loading: action.payload.loading,
        results: action.payload.results,
        categories: action.payload.categories,
      };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_RESULTS':
      return { ...state, results: action.payload };
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload };
    case 'SET_SEARCH_TERM':
      return { ...state, searchTerm: action.payload };
    case 'SET_TYPING_TIMEOUT':
      return { ...state, typingTimeout: action.payload };
    default:
      return state;
  }
};

export { initialState, autoCompleteReducer };
