import React from 'react';
import AutoCompleteSectionHeader from './AutoCompleteSectionHeader';

const PopularInfo = ({ resultsLength }) => {
  if (resultsLength === 0) {
    return (
      <div className="search-autocomplete__suggestions--popular">
        <AutoCompleteSectionHeader content="Recently Viewed" />
        <p>Extra content</p>
        <a href="/">Test link</a>

        <AutoCompleteSectionHeader content="Recent Searches" />
        <p>Extra content</p>

        <AutoCompleteSectionHeader content="Popular Searches" />
        <p>Extra content</p>

        <AutoCompleteSectionHeader content="Popular Categories" />
        <p>Extra content</p>
      </div>
    );
  }

  return (
    <div className="search-autocomplete__suggestions--popular">
      <AutoCompleteSectionHeader content="Categories" />
      <p>Extra content</p>

      <AutoCompleteSectionHeader content="Recent Searches" />
      <p>Extra content</p>
    </div>
  );
};

export default PopularInfo;
