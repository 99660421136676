import React, { useEffect, useReducer } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import { useHTTP } from 'hooks/useRequest';
import { PlusIcon } from 'components/shared/CommonSvgs';
import { basketNoteReducer } from 'reducers/basketNoteReducer';

const BasketNote = ({ id, productId, quantity, notes }) => {
  const [state, dispatch] = useReducer(basketNoteReducer, {
    basketLineNote: notes,
    noteFocussed: false,
    requestConfig: {
      url: null,
      method: null,
      headers: {
        Accept: 'application/json',
      },
    },
    id,
    productId,
    quantity,
  });

  const { basketLineNote, noteFocussed, requestConfig } = state;
  const { data } = useHTTP(requestConfig);

  const noteUnfocussedWithoutNote = !noteFocussed && !basketLineNote;
  const noteUnfocussedWithNote = !noteFocussed && basketLineNote;

  const toggleNoteFocus = () => {
    dispatch({ type: 'TOGGLE_FOCUS' });
    if (noteFocussed) {
      dispatch({
        type: 'SET_REQUEST_CONFIG',
        payload: {
          url: `/basket_lines/${id}`,
          method: 'patch',
          headers: {
            Accept: 'application/json',
          },
          data: {
            product_id: productId,
            notes: basketLineNote || null,
            quantity,
          },
        },
      });
    }
  };

  useEffect(() => {
    // Reset the config so requests fire correctly
    dispatch({
      type: 'SET_REQUEST_CONFIG',
      payload: {
        url: null,
        method: null,
        headers: {
          Accept: 'application/json',
        },
      },
    });
  }, [data, basketLineNote]);

  return (
    <div className="basket-note fl ai-c width-100">
      {noteUnfocussedWithoutNote && (
        <Button
          className="basket-note__add-note-button bg-none b-none td-u mt-20 p-0 c--black-blue"
          handleClick={toggleNoteFocus}>
          <PlusIcon className="mr-5" />
          Add note
        </Button>
      )}
      <label
        className={`basket-note__label fl mt-20 br-4 width-100
                    ${noteUnfocussedWithNote ? ' fl-row p-4 plr-14 bgc--grey' : ' fl-col'}
                    ${!basketLineNote && !noteFocussed ? ' d-none' : ''}`}
        htmlFor={`basket-note__input-${id}`}>
        {noteUnfocussedWithNote && (
          <span className="fl ai-c mr-8 fw-700 c--grey50">NOTE</span>
        )}
        <input
          className={`basket-note__input width-100 mr-5${noteFocussed ? ' mb-10' : ' b-none'}`}
          placeholder="Add details here"
          id={`basket-note__input-${id}`}
          onChange={e =>
            dispatch({ type: 'SET_NOTE', payload: e.target.value })
          }
          disabled={!noteFocussed}
          type="text"
          value={basketLineNote || ''}
        />
        {noteUnfocussedWithNote && (
          <Button
            className="basket-note__edit-button p-10 br-4 td-u bg-none b-none"
            handleClick={toggleNoteFocus}>
            Edit
          </Button>
        )}
        {noteFocussed && (
          <div className="fl">
            <Button
              className={`basket-note__confirm-button btn-black-blue plr-16 mr-12
                          ${noteUnfocussedWithoutNote ? ' d-none' : ''}`}
              handleClick={toggleNoteFocus}>
              Confirm
            </Button>
            <Button
              className={`basket-note__confirm-button btn-black-blue--as-link bg-none td-u
                          ${noteUnfocussedWithoutNote ? ' d-none' : ''}`}
              handleClick={() => dispatch({ type: 'REMOVE_NOTE' })}>
              Remove
            </Button>
          </div>
        )}
      </label>
    </div>
  );
};

export default BasketNote;
