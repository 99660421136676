import React from 'react';

const NoResults = () => (
  <tr key={0}>
    <td className="no-results">
      <div className="alert alert-warning" role="alert">
        <p>
          <strong>Sorry, no products found</strong>
        </p>
        <p className="message">Try a different search term</p>
      </div>
    </td>
  </tr>
);

export default NoResults;
