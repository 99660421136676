import { useReducer, useEffect, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useHTTP } from 'hooks/useRequest';
import useBasketStore from 'stores/basketStore/useBasketStore';
import useToastStore from 'stores/useToastStore';

import addToBasketReducer, {
  initialState,
} from 'components/AddProductToBasket/addToBasketReducer';
import {
  setMultiplesOf,
  setQuantLessThanMinOrder,
  setQuantMoreThanMaxQuant,
  setEmptyQuant,
  setInputChange,
  setQuantCorrect,
  setBackorderModal,
  setProductAdded,
  setAddingProduct,
} from 'components/AddProductToBasket/addToBasketActionCreator';
import {
  isMinOrderQuantityValid,
  isMaxOrderQuantityValid,
  isQuantityMultipleOfMinOrderValid,
} from 'utils/quantityUpdates';

/**
 * Custom React hook for managing "Add to Basket" functionality.
 * Handles quantity management, API requests, and state synchronization.
 *
 * @param {Object} currentProduct The product currently being added to the basket.
 * @param {number} quantity The current quantity selected by the user.
 * @param {number} stockLevel The available stock level of the product.
 * @param {number|null} lineId The ID of the basket line if editing an existing line.
 * @returns {{
 *   state: Object,
 *   quantOnChange: Function,
 *   quantOnBlur: Function,
 *   increaseQuantity: Function,
 *   reduceQuantity: Function,
 *   handleAddButtonClick: Function,
 *   handleModalAdd: Function,
 *   closeBackOrderModal: Function
 * }} Object containing state and callbacks for managing the "Add to Basket" feature.
 */
const useAddToBasket = (
  currentProduct,
  quantity,
  stockLevel,
  lineId,
  notes,
) => {
  const { addToast } = useToastStore();
  const [state, dispatch] = useReducer(addToBasketReducer, initialState);
  const { count, minimumOrder, maximumQuantity, addProduct } = state;

  const url = lineId ? `/basket_lines/${lineId}` : '/basket'; // Determine API endpoint URL
  const prodId = currentProduct.id; // Get product ID

  // Define data payload based on whether editing existing line or adding new product
  const addToBasketData = lineId
    ? { id: +lineId, quantity: count, notes }
    : {
        basket: {
          lines_attributes: {
            0: {
              product_id: +prodId,
              quantity: count,
              notes,
            },
          },
        },
      };

  const requestConfig = {
    url: addProduct ? url : null,
    method: lineId ? 'patch' : 'put',
    headers: { Accept: 'application/json' },
    data: { ...addToBasketData },
  };

  const { error, data } = useHTTP(requestConfig); // Make HTTP request using custom hook

  // Access specific functions from useBasketStore custom hook
  const {
    addBasketLine,
    setBasketGoodsTotal,
    setCurrentBasket,
    onBasketPage,
    basket,
  } = useBasketStore(basketState => ({
    basket: basketState.basket,
    onBasketPage: basketState.onBasketPage,
    setCurrentBasket: basketState.setCurrentBasket,
    addBasketLine: basketState.addBasketLine,
    setBasketGoodsTotal: basketState.setBasketGoodsTotal,
  }));

  // Synchronize state with initial quantity
  useEffect(() => {
    dispatch({
      type: 'SET_LOADED_STATE',
      payload: {
        onBasketPage,
        count: lineId ? Number(quantity) : Number(currentProduct.minimum_order),
        minimumOrder: Number(currentProduct.minimum_order),
        maximumQuantity: Number(currentProduct.maximum_quantity),
        deliveryMinOrder: Number(currentProduct.delivery_minimum_order),
      },
    });
  }, [quantity, currentProduct, lineId]);

  // Handle API response and errors
  useEffect(() => {
    if (error) {
      // Handle error toast notification
      const productError = lineId
        ? currentProduct.description_short
        : currentProduct.description;
      addToast('There was an error updating this product.', productError);
    } else if (data) {
      // Handle successful response
      const {
        basket: { lines, goods_total: goodsTotal },
      } = data;
      const productToAdd = lines.find(({ product: { id } }) => id === prodId);

      addBasketLine(productToAdd); // Add product line to basket
      setBasketGoodsTotal(goodsTotal); // Set total goods amount
      setCurrentBasket(data.basket); // Set current basket state
      dispatch(setProductAdded(true, false, false)); // Update state flag
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });

      // Display appropriate toast message based on action anywhere but basket
      // Second conditional checks for not backorder
      if (!lineId && count <= stockLevel && stockLevel > 0) {
        addToast('was added to your Basket.', currentProduct.description);
      }
    }
  }, [data, error]);

  // Event handler for "Add to Basket" button click
  const handleAddButtonClick = useCallback(() => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    if (count > stockLevel && stockLevel > 0 && !lineId) {
      dispatch(setBackorderModal(true, true)); // Show backorder modal
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
      return;
    }
    if (stockLevel <= 0 && !lineId) {
      dispatch(setBackorderModal(true)); // Show backorder modal
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
      return;
    }

    const productInBasket = basket.lines.find(
      ({ product: { id } }) => id === prodId,
    );
    if (productInBasket) {
      const { quantity: prodInBasketQuantity } = productInBasket;
      // if product in basket and new quant exceeds stock
      if (prodInBasketQuantity + count > stockLevel && !lineId) {
        dispatch(setBackorderModal(true, true));
        dispatch({
          type: 'SET_LOADING',
          payload: false,
        });
        return;
      }
    }

    dispatch(setAddingProduct(true, true)); // Set adding product state
  }, [count, stockLevel, lineId, basket]);

  // Handle adding product via modal
  const handleModalAdd = useCallback(() => {
    dispatch(setBackorderModal(false, false)); // Close backorder modal
    dispatch(setAddingProduct(true, true)); // Set adding product state
    addToast('was added to your Basket.', currentProduct.description); // Show toast notification
  }, [addToast, currentProduct.description]);

  // Handle quantity change event
  const quantOnChange = useCallback(e => {
    const { value: qty } = e.target;
    if (qty === '') {
      dispatch(setEmptyQuant()); // Dispatch empty quantity action
      return;
    }
    dispatch(setInputChange(+qty)); // Dispatch quantity change action
  }, []);

  // Handle quantity blur event
  const quantOnBlur = useCallback(
    e => {
      const { value: qty } = e.target;
      // Validate quantity and dispatch appropriate action
      if (isMinOrderQuantityValid(+qty, minimumOrder)) {
        dispatch(setQuantLessThanMinOrder(+minimumOrder));
        return;
      }
      if (isMaxOrderQuantityValid(+qty, maximumQuantity)) {
        dispatch(setQuantMoreThanMaxQuant(+maximumQuantity));
        return;
      }
      if (isQuantityMultipleOfMinOrderValid(+qty, minimumOrder)) {
        dispatch(setMultiplesOf(minimumOrder));
        return;
      }
      dispatch(setQuantCorrect(+qty)); // Dispatch correct quantity action

      if (lineId) {
        handleAddButtonClick(); // Handle button click action
      }
    },
    [handleAddButtonClick, minimumOrder, maximumQuantity, lineId],
  );

  // Close backorder modal
  const closeBackOrderModal = useCallback(() => {
    dispatch(setBackorderModal(false, false)); // Dispatch close backorder modal action
  }, []);

  // Debounced handler for increasing quantity
  const increaseQuantity = useDebouncedCallback(qty => {
    if (isMaxOrderQuantityValid(qty, maximumQuantity)) {
      dispatch(setQuantMoreThanMaxQuant(+maximumQuantity)); // Dispatch max quantity action
    }
    dispatch(setQuantCorrect(+qty)); // Dispatch correct quantity action

    if (lineId) {
      handleAddButtonClick(); // Handle button click action
    }
  }, 100);

  // Debounced handler for reducing quantity
  const reduceQuantity = useDebouncedCallback(qty => {
    if (qty === count) return; // If quantity same, return
    if (isMinOrderQuantityValid(qty, minimumOrder)) {
      dispatch(setQuantLessThanMinOrder(+minimumOrder)); // Dispatch min quantity action
    }
    dispatch(setQuantCorrect(+qty)); // Dispatch correct quantity action

    if (lineId) {
      handleAddButtonClick(); // Handle button click action
    }
  }, 100);

  // Return state and callback functions
  return {
    currentBasket: basket,
    state,
    quantOnChange,
    quantOnBlur,
    increaseQuantity,
    reduceQuantity,
    handleAddButtonClick,
    handleModalAdd,
    closeBackOrderModal,
  };
};

export default useAddToBasket;
