import React from 'react';
import {
  AutocompleteCloseIcon,
  CircleCheck,
  CircleCrossIcon,
} from 'components/shared/CommonSvgs';
import { white } from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const Toast = ({ message: m, type, description, onClose }) => {
  const GetToastContent = () => {
    switch (true) {
      case ['info', 'notice'].includes(type):
        return (
          <>
            <div className="toast-icon fl ai-c jc-c mr-8">
              <CircleCheck width="24" height="24" />
            </div>
            <div className="toast-content fl fl-col mr-8">
              {description && (
                <p className="fs-14 m-0 mb-8 p-0 ellipsis ellipsis--large">
                  {description}
                </p>
              )}
              <p className="fs-14 m-0 p-0">{m}</p>
            </div>
          </>
        );

      case ['decline', 'alert'].includes(type):
        return (
          <>
            <div className="toast-icon fl ai-c jc-c mr-8">
              <CircleCrossIcon fillColor={white} width="24" height="24" />
            </div>
            <div className="toast-content fl fl-col mr-8">
              {description && (
                <p className="fs-14 m-0 mb-8 p-0 ellipsis ellipsis--large">
                  {description}
                </p>
              )}
              <p className="fs-14 m-0 p-0">{m}</p>
            </div>
          </>
        );

      default:
        return (
          <div className="toast-content fl fl-col">
            {description && (
              <p className="fs-14 m-0 mb-8 p-0 ellipsis ellipsis--large">
                {description}
              </p>
            )}
            <p className="fs-14 m-0 p-0">{m}</p>
          </div>
        );
    }
  };

  const getContainerClass = () => {
    switch (true) {
      case ['decline', 'alert'].includes(type):
        return 'b-colour--red';
      default:
        return 'b-colour--blue10';
    }
  };

  return (
    <div
      className={`toast-container fl ai-c jc-fs ptb-16 plr-12 ${getContainerClass()}`}>
      <GetToastContent />
      <button
        className="toast-container__dismiss"
        type="button"
        aria-label="Dismiss Toast"
        onClick={onClose}>
        <AutocompleteCloseIcon className="ml-16" fillColor={white} />
      </button>
    </div>
  );
};

export default Toast;
