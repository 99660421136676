import React from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import styled from 'styled-components';
import useDropdown from 'hooks/useDropdown';
import { AdminAccountIcon } from 'components/shared/CommonSvgs';
import Dropdown from 'components/shared/Dropdown';
import { Links } from 'common/accountSidebarLinks';

const HeaderAccountInfo = ({ initials, accountName, links }) => {
  const pillIndex = 1;
  const { handleClick } = useDropdown(pillIndex);
  const DropdownAction = () => (
    <DropdownActionButton
      type="button"
      className="header-dropdown-button plr-10 m-plr-20 ptb-12 fl ai-c
        jc-c br-100 btn-header-grey fw-400 td-none"
      label="Account"
      handleClick={handleClick}
      aria-haspopup="true">
      <AdminAccountIcon
        className="header-dropdown-button__icon--mobile"
        viewBox="0 0 20 20"
      />

      <div className="site-banner__initials d-none m-fl ai-c jc-c">
        {initials}
      </div>

      <div className="site-banner__account-info d-none m-fl fl-col ml-10">
        <span className="fs-14">Account</span>
        <span className="ellipsis">{accountName}</span>
      </div>
    </DropdownActionButton>
  );
  return (
    <Dropdown
      className="account-info"
      dropdownAction={DropdownAction}
      pillIndex={pillIndex}
      count={links.length}>
      <div className="header-dropdown-button-dropdown__title plr-16">
        <h3 className="fs-20 fw-700 ta-l m-0">Account</h3>
      </div>
      <div className="fl fl-col">
        {Links.map(({ url, icon, text }, index, arr) => (
          <a
            key={text}
            href={url}
            role="menuitem"
            className={`account-sidebar__link fl ai-c p-10 bt b-colour--grey
            td-none c--black-blue ${arr.length - 1 === index ? 'bb' : ''}`}>
            <div className="fl ai-c mr-5">{icon}</div>
            {text}
          </a>
        ))}
      </div>
      <div className="fl fl-col">
        <a
          href="/users/sign_out"
          className={`sign-out-button fl ai-c p-10 bt b-colour--grey
            td-none fw-700`}>
          Sign Out
        </a>
      </div>
    </Dropdown>
  );
};

const DropdownActionButton = styled(Button)`
  min-height: 32px;
  min-width: 32px;
`;

export default HeaderAccountInfo;
