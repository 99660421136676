// itemDataUtils.js
const createItemData = ({
  id,
  catalogue,
  description,
  brand,
  categories,
  rrp,
  unit,
  quantity,
  itemListId = 'default_list_id',
  itemListName = 'Default List Name',
  index = 0,
  price = null,
  productCheapestPrice = null,
  categoryDescriptions = [],
  isProductList = false,
  minimumOrder = null,
}) => ({
  item_id: id || null,
  index: index + 1, // Increment index for lists
  currency: 'GBP',
  part_code: catalogue || null,
  item_name: description || null,
  item_brand: brand || null,
  item_category: categories?.[0]?.name || categoryDescriptions?.[0] || null,
  item_category2: categories?.[1]?.name || categoryDescriptions?.[1] || null,
  item_category3: categories?.[2]?.name || categoryDescriptions?.[2] || null,
  item_list_id: isProductList ? 'product_list' : itemListId,
  item_list_name: isProductList ? 'Product List' : itemListName,
  price: unit ? `£${unit}` : price || productCheapestPrice || null,
  rrp: rrp ? `£${rrp}` : null,
  quantity: quantity || minimumOrder || null,
});

export { createItemData };
