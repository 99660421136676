import React from 'react';
import ProductSlider from 'components/shared/ProductSlider';
import MainSliderBanner from './MainSliderBanner';

const MainSlider = ({ items }) => (
  <ProductSlider
    options={{
      type: 'loop',
      autoplay: true,
      rewind: false,
      interval: 5000,
      pauseOnHover: true,
      lazyLoad: 'nearby',
      pagination: true,
      arrows: false,
      speed: 800,
      perPage: 1,
    }}
    numberOfItems={items.length}
    showArrows
    showPagination>
    <ul className="splide__list">
      {items.map(({ id, mobile_image: mobileImage, image: img, url, name }) => (
        <MainSliderBanner
          name={name}
          key={id}
          id={id}
          url={url}
          mobileImage={mobileImage}
          desktopImage={img}
        />
      ))}
    </ul>
  </ProductSlider>
);

export default MainSlider;
