import React from 'react';
import { CategoryIcon } from '../shared/CommonSvgs';
import AutoCompleteSectionHeader from './AutoCompleteSectionHeader';
import HighlightSearchTerm from './HighlightSearchTerm';

const CategoryResults = ({ categories, searchTerm }) => (
  <div className="search-autocomplete__category-results">
    {categories.length > 0 && (
      <AutoCompleteSectionHeader content="Categories" />
    )}
    <ul className="p-0 m-0 ls-none">
      {categories.map(({ url, name, id }) => (
        <li key={id}>
          <a
            href={url}
            aria-label={name}
            className="fl ai-c td-none ptb-12 c--black-blue">
            <CategoryIcon className="mr-5" />
            <p className="m-0">
              <HighlightSearchTerm resultText={name} searchTerm={searchTerm} />
            </p>
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default CategoryResults;
