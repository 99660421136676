import React from 'react';
import OrderSummaryCard from './OrderSummaryCard';

const RecentOrders = ({ recentOrders }) => (
  <div>
    {recentOrders.map(
      ({
        order_id: id,
        created_at: createdAt,
        goods,
        products,
        status,
        purchase_order: purchaseOrder,
      }) => {
        const orderPlaced = new Date(createdAt);
        return (
          <OrderSummaryCard
            key={id}
            date={orderPlaced}
            price={goods}
            orderNumber={id}
            purchaseOrder={purchaseOrder}
            products={products}
            status={status}
          />
        );
      },
    )}
  </div>
);

export default RecentOrders;
