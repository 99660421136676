import React from 'react';
import { LoadingSpinner } from './CommonSvgs';

const LoadingSpinnerComponent = ({ className, width, height }) => (
  <div className={className} role="status">
    <LoadingSpinner className="animate-spin" width={width} height={height} />
    <span className="sr-only">Loading...</span>
  </div>
);

export default LoadingSpinnerComponent;
