import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ArrowDownIcon, ArrowUpIcon } from 'components/shared/CommonSvgs';
import toRem from 'utils/toRem';
import { white } from '../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const ProductSorting = ({
  setSort,
  sortingOptions,
  checkToggle,
  mobileSort,
  fullPageSort,
  toggleOpen,
  sortDisplay,
}) => {
  const [selectedSort, setSelectedSort] = useState('Sort By');
  const [toggled, setToggled] = useState(true);

  useEffect(() => {
    setSelectedSort(sortDisplay);
  }, [sortDisplay]);

  const setSortOption = index => {
    setToggled(false);
    setSort(sortingOptions[index].value);
    setSelectedSort(sortingOptions[index].display);
  };

  const toggleOptionsDisplay = () => {
    if (document.querySelector('.product-filters--open') && !toggled) {
      document.querySelector('.filter-toggle__button').click();
    }

    const propsToggled = !checkToggle();
    checkToggle(propsToggled);

    if (fullPageSort && propsToggled) {
      setTimeout(() => {
        document.querySelector('.products-options__sort').scrollIntoView();
        document.body.classList.toggle('no-scroll');
      }, 100);
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const OptionsList = () => (
    <SortOptions className="sort-options ls-none b-full br-4">
      {sortingOptions.map((option, index) => (
        <li
          className={
            selectedSort === option.display
              ? 'sort-options__item sort-options__item--selected'
              : 'sort-options__item'
          }
          key={option.value}>
          <button
            onClick={() => {
              setSortOption(index);
              toggleOptionsDisplay();
            }}
            type="button"
            className="fl ai-c m-0 b-none bg-none">
            {option.display}
          </button>
        </li>
      ))}
    </SortOptions>
  );

  return (
    <div className="fl">
      {!mobileSort && (
        <p className="products-options__sort-by-text mr-6">Sort By</p>
      )}
      <SortBy
        className={`products-options__sort ${toggleOpen ? 'open' : ''}
        p-10 br-4 b-full b-colour--grey`}
        onClick={() => toggleOptionsDisplay()}>
        <div className="products-options__sort-options">
          <span className="product-options__sort-options--selected">
            {toggleOpen && fullPageSort ? 'Sort by' : selectedSort}
          </span>
          {toggleOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
        {toggleOpen && <OptionsList />}
      </SortBy>
    </div>
  );
};

const SortBy = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  padding: 0 0.5rem 0 1rem;
  position: relative;
  width: ${toRem(158)};

  > .products-options__sort-options {
    align-items: center;
    display: flex;
    height: 34px;
    justify-content: space-between;
    width: 100%;
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 180px;
  }
`;

const SortOptions = styled.ul`
  background-color: ${white};
  left: -2px;
  padding: 1rem;
  position: absolute;
  top: 36px;
  width: 205px;
  z-index: 10;

  .sort-options__item:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .sort-options__item--selected {
    color: #2ecc40;
  }

  .sort-options__item button:hover {
    color: #2ecc40;
    cursor: pointer;
  }

  .sort-options__item > svg {
    margin-right: 0.25rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 180px;
  }
`;

export default ProductSorting;
