import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Button } from '@cityelectricalfactors/ui-components';
import ModalContentComponent from 'components/shared/ModalContentComponent';
import Input from 'components/UI/Input';
import {
  blackBlue85,
  grey20,
} from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

/**
 * Props for the component.
 *
 * @typedef {Object} Props
 * @property {boolean} modalIsOpen - Indicates whether the modal is open.
 * @property {Function} closeModal - Function to close the modal.
 * @property {Function} successCallback - Callback function to be called on success.
 * @property {boolean} insufficientStock - Indicates if there is insufficient stock.
 * @property {number} stockLevel - The current stock level.
 * @property {number} quantity - The quantity of the product.
 * @property {string} productDescription - The description of the product.
 */

const BackOrderModal = ({
  modalIsOpen,
  closeModal,
  successCallback,
  insufficientStock,
  stockLevel,
  quantity,
  productDescription,
  currentBasket,
  productId,
}) => {
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [closeModal]);

  const isProductInBasket = currentBasket?.lines?.find(
    ({ product: { id } }) => id === productId,
  );

  return (
    <StyledModal isOpen={modalIsOpen} closeModal={closeModal}>
      <ModalContentComponent
        stockLevel={stockLevel}
        closeModal={closeModal}
        quantity={quantity}
        insufficientStock={insufficientStock}
        productDescription={productDescription}
        isProductInBasket={isProductInBasket}
      />

      <ModalFooter>
        <div className="mb-16 fl jc-c">
          <ConfirmationContainer>
            <Input
              className="back-order__checkbox mr-5"
              type="checkbox"
              name="confirmBackorder"
              id="confirmBackorder"
              ariaLabel="Confirm Backorder"
              label={
                insufficientStock
                  ? 'I am aware there is insufficient stock and delivery dates will be confirmed.'
                  : 'I am aware this item is out of stock and delivery dates will be confirmed.'
              }
              labelClassName="fs-14"
              textRight
              checked={isChecked}
              handleChange={() => {
                setChecked(!isChecked);
              }}
            />
          </ConfirmationContainer>
        </div>
        <ButtonContainer>
          <AddToBasketButton
            className="pap-btn--xl btn-black-blue br-4"
            type="button"
            disabled={!isChecked}
            aria-label="Place on Backorder"
            handleClick={() => {
              successCallback();
            }}
            label="Place on Backorder">
            Place on Backorder
          </AddToBasketButton>
        </ButtonContainer>
      </ModalFooter>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  > div {
    border-radius: 8px;
    padding: 0;
    width: 90%;
    max-width: 647px;
    > h2 {
      color: ${blackBlue85};
    }
  }
`;

const ModalFooter = styled.div`
  padding: 1.5rem;
`;

const ConfirmationContainer = styled.div`
  font-size: 0.875rem;
  display: flex;
  border: 1px solid ${grey20};
  border-radius: 4px;
  padding: 0.5rem;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 768px) {
    justify-content: end;
  }
`;

const AddToBasketButton = styled(Button)`
  font-weight: 500;
  border: 0;
  padding: 0;
  width: 48%;
  height: 44px;
  @media only screen and (min-width: 768px) {
    width: 25%;
  }
`;

export default BackOrderModal;
