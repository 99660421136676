import React, { useState, useEffect } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import { useHTTP } from 'hooks/useRequest';
import useUserCompareStore from 'stores/userCompareStore/useUserCompareStore';
import useToastStore from 'stores/useToastStore';
import CompareProductsToShow from './CompareProductsToShow';

const ProductCompare = ({
  location,
  currentCompareProducts,
  comparePageUrl,
}) => {
  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: '',
    headers: {
      Accept: 'application/json',
    },
  });
  const { addToast } = useToastStore();

  const { userCompareProducts, addCurrentCompareProducts } =
    useUserCompareStore(state => ({
      userCompareProducts: state.userCompareProducts,
      addCurrentCompareProducts: state.addCurrentCompareProducts,
    }));

  useEffect(() => {
    if (currentCompareProducts) {
      addCurrentCompareProducts(currentCompareProducts);
    }
  }, [currentCompareProducts]);

  const { error, data } = useHTTP(requestConfig);

  useEffect(() => {
    if (error) {
      addToast('Error adding to compare', null, 'decline');
    }
    if (data) {
      addCurrentCompareProducts([]);
      addToast(data.message);
    }
  }, [error, data]);

  const clearAll = () => {
    const idsToRemove = userCompareProducts.map(item => item.id);
    setRequestConfig({
      url: `${comparePageUrl}/${idsToRemove}`,
      method: 'delete',
      headers: {
        Accept: 'application/json',
      },
    });
  };

  return (
    <div
      className={`user-compare ${!!userCompareProducts.length && 'user-compare__is-on'}`}>
      <div className="v2-container">
        <div
          className={`user-compare-bar ${location} ${!location && 'ml-auto'} m-fl ai-c jc-sb`}>
          <div className="user-compare-bar__products fl ai-c">
            <CompareProductsToShow
              products={userCompareProducts}
              location={location}
            />
          </div>
          <div className="user-compare-bar__actions fl ai-c">
            <a
              href={comparePageUrl}
              className={`p-16 mr-8 btn btn-black-blue br-4 bg-none fw-700 
              ${userCompareProducts.length === 1 && 'disabled'}`}>
              Compare Selection ({userCompareProducts.length}/5)
            </a>

            <Button
              handleClick={() => clearAll()}
              className="user-compare-bar__action-clear fs-14 td-u c--black-blue">
              Clear all
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCompare;
