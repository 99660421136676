import React, { useRef } from 'react';
import BackOrderModal from 'components/AddProductToBasket/BackOrderModal';
import LoadingSpinnerComponent from 'components/shared/LoadingSpinnerComponent';
import { pushToDataLayer } from 'components/shared/PushToDatalayer';

const AddToBasket = ({
  count,
  product_id: productId,
  disabled,
  product,
  stockLevel,
  handleAdd,
  handleAddToBasket,
  closeBackOrderModal,
  loading,
  showModal,
  insufficientStock,
  basketSuccess,
  isListView,
  itemData,
  currentBasket,
}) => {
  const addRef = useRef(null);

  const handleAddButton = () => {
    handleAddToBasket(productId);

    if (stockLevel > 0) {
      itemData.quantity = count;
      pushToDataLayer('add_to_cart', itemData, {
        add_to_cart_location: itemData.item_list_name,
      });
    }
  };

  const handleSuccessCallback = () => {
    handleAdd();
    itemData.quantity = count;
    pushToDataLayer('add_to_cart', itemData, {
      add_to_cart_location: itemData.item_list_name,
    });
  };

  const basketSuccessClass =
    basketSuccess === true ? 'add-to-basket__button--submit_success' : '';

  return (
    <div className={`add-to-basket__button ${isListView ? 'col-8' : 'col-9'}`}>
      <button
        disabled={count === 0 || Number.isNaN(count) || disabled}
        type="button"
        ref={addRef}
        className={`add-to-basket__button--submit ${basketSuccessClass} 
        btn btn-blue col-9 br-4 width-100 fw-700`}
        onClick={() => handleAddButton()}>
        {loading ? (
          <LoadingSpinnerComponent className="fl fl-1 jc-c" />
        ) : (
          <span>{basketSuccess ? 'Added to Basket' : 'Add to Basket'}</span>
        )}
      </button>
      <BackOrderModal
        currentBasket={currentBasket}
        productId={productId}
        productDescription={product.description}
        modalIsOpen={showModal}
        closeModal={() => closeBackOrderModal()}
        stockLevel={stockLevel}
        quantity={count}
        insufficientStock={insufficientStock}
        successCallback={() => handleSuccessCallback()}
      />
    </div>
  );
};

export { AddToBasket as default };
