import React from 'react';

import OfferPod from './offerPod';
import LoadingAnimation from './loading.animation';

const LoadingState = props => (
  <OfferPod offer={props.offer}>
    <LoadingAnimation />
  </OfferPod>
);

export default LoadingState;
