const isMaxOrderQuantityValid = (qty, maxOrderVal) => qty > maxOrderVal;
const isMinOrderQuantityValid = (qty, minOrderVal) => qty < minOrderVal;
const isQuantityMultipleOfMinOrderValid = (qty, minOrderVal) =>
  qty % minOrderVal !== 0;

export {
  isMinOrderQuantityValid,
  isMaxOrderQuantityValid,
  isQuantityMultipleOfMinOrderValid,
};
