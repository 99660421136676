import React, { useEffect, useRef } from 'react';
import useToastStore from 'stores/useToastStore';
import ToastContainer from './ToastContainer';

const ToastNotification = ({ flashMessages }) => {
  const { toasts, addToast, removeToast } = useToastStore();
  const parsedFlashMessages = useRef(JSON.parse(flashMessages)).current;

  useEffect(() => {
    if (parsedFlashMessages.length) {
      parsedFlashMessages.forEach(({ type, message }) => {
        addToast(message, null, type);
      });
    }
  }, [parsedFlashMessages, addToast]);

  return (
    <ToastContainer
      toasts={toasts}
      removeToast={removeToast}
      position="top-right"
    />
  );
};

export default ToastNotification;
