import React, { useEffect, useState } from 'react';

import { WhiteCheck } from 'components/shared/CommonSvgs';
import { useCookie } from 'hooks/useCookies';

const PriceSwitch = ({ signedIn }) => {
  const [toggled, setToggled] = useState(signedIn || false);
  const [pricingCookieValue, updateCookie] = useCookie('show_pricing');
  const parsedPricingCookieValue = JSON.parse(pricingCookieValue);

  useEffect(() => {
    if (signedIn && parsedPricingCookieValue === null) {
      updateCookie(true);
      setToggled(true);
    } else if (signedIn && parsedPricingCookieValue !== null) {
      updateCookie(parsedPricingCookieValue);
      setToggled(parsedPricingCookieValue);
    }
  }, []);

  useEffect(() => {
    if (parsedPricingCookieValue && parsedPricingCookieValue !== null) {
      setToggled(parsedPricingCookieValue);
    }
  }, [parsedPricingCookieValue]);

  const toggle = () => {
    setToggled(!toggled);
    updateCookie(!toggled);
    window.location.reload();
  };

  return (
    <div className="hide-site-prices fl ai-c">
      <div className="switch-container">
        <label
          className={`toggle-switch ${toggled ? 'toggle-switch--checked' : ''}`}
          htmlFor="slide-toggle"
          title={toggled ? 'Showing prices' : 'Hiding prices'}>
          <input
            type="checkbox"
            id="slide-toggle"
            className="toggle-switch-input"
            checked={toggled}
            onChange={toggle}
          />
          <div className="toggle-switch-handle">
            {toggled && <WhiteCheck className="switch-icon switch-icon-on" />}
          </div>
        </label>
      </div>
      <p className="fs-14 m-0 ml-8">{toggled ? 'Showing' : 'Hiding'}</p>
    </div>
  );
};

export default PriceSwitch;
