import React from 'react';
import { Button } from '@cityelectricalfactors/ui-components';

/* This is just a syntax update to the old component. */
const SearchResultPromotion = ({
  productData,
  productData: {
    id,
    small_image_url: smallImageUrl,
    description,
    catalogue,
    price,
  },
  selectProduct,
}) => {
  const setProduct = () => {
    if (selectProduct) {
      selectProduct(productData);
    }
  };

  return (
    <tr key={`search-result-${id}`} className="product">
      <td>
        <Button handleClick={setProduct} className="fl ai-c jc-c">
          <img src={smallImageUrl} alt="Product" />
          <div className="fl fl-col">
            <p>
              {description}
              <br />
              <b>Catalogue #: {catalogue}</b>
            </p>
            <p>£{Number(price.goods).toFixed(2)}</p>
          </div>
        </Button>
      </td>
    </tr>
  );
};

export default SearchResultPromotion;
