import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@cityelectricalfactors/ui-components';
import useToastStore from 'stores/useToastStore';
import GlobalModal from 'components/shared/GlobalModal';
import useDelete from 'hooks/useDelete';
import toRem from 'utils/toRem';

/**
 * DeleteItem component for deleting an item and showing a confirmation modal.
 *
 * @param {Object} props - The component props.
 * @param {string} props.url - The URL to send the delete request to.
 * @param {string} props.className - Additional class name for the button.
 * @param {boolean} props.refreshOnClose - Flag to refresh the page on modal close.
 * @param {string} props.mainButtonText - The text for the main button.
 * @param {string} props.modalTitle - The title of the modal.
 * @param {string} props.modalContent - The content of the modal.
 * @param {string} props.modalButtonContent - The content of the modal button.
 * @param {function} [props.onSuccess] - Optional success callback function.
 * @param {function} [props.onError] - Optional error callback function.
 * @returns {React.Element} The DeleteItem component.
 */
const DeleteItem = ({
  url,
  className,
  refreshOnClose,
  mainButtonText,
  modalTitle,
  modalContent,
  modalButtonContent,
  onSuccess: propOnSuccess,
  onError: propOnError,
  closeModal,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const { addToast } = useToastStore();

  /**
   * Default success callback for the delete request.
   *
   * @param {Object} data - The response data from the delete request.
   */
  const defaultOnSuccess = data => {
    const message = data?.message;
    setSuccessMessage(message);
  };

  /**
   * Default error callback for the delete request.
   *
   * @param {Object} errorResponse - The error response from the delete request.
   * @param {Object} errorResponse.error - The error object.
   * @param {Object} errorResponse.error.data - The error data object.
   * @param {string} errorResponse.error.data.message - The error message.
   */
  const defaultOnError = ({ error }) => {
    const message = error?.data?.message;
    if (message) {
      setShowModal(false);
      addToast(message, null, 'decline');
    }
  };

  /**
   * Closes the global modal and refreshes the page if needed.
   */
  const closeGlobalModal = () => {
    setShowModal(false);
    if (refreshOnClose) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (closeModal) {
      closeGlobalModal();
    }
  }, [closeModal]);

  const { handleRemove } = useDelete(
    url,
    propOnSuccess || defaultOnSuccess,
    propOnError || defaultOnError,
  );

  return (
    <>
      <DeleteItemButton
        className={className}
        type="button"
        aria-label={mainButtonText}
        label={mainButtonText}
        handleClick={() => setShowModal(true)}>
        {mainButtonText}
      </DeleteItemButton>
      <GlobalModal
        buttonMessage={modalButtonContent}
        title={modalTitle}
        content={modalContent}
        modalIsOpen={showModal}
        successMessage={successMessage}
        closeModal={closeGlobalModal}
        successCallback={handleRemove}
      />
    </>
  );
};

const DeleteItemButton = styled(Button)`
  min-height: ${toRem(42)};
`;

export default DeleteItem;
