const basketNoteReducer = (state, action) => {
  switch (action.type) {
    case 'SET_NOTE':
      return {
        ...state,
        basketLineNote: action.payload,
      };
    case 'TOGGLE_FOCUS':
      return {
        ...state,
        noteFocussed: !state.noteFocussed,
      };
    case 'REMOVE_NOTE':
      return {
        ...state,
        basketLineNote: '',
        noteFocussed: false,
        requestConfig: {
          url: `/basket_lines/${state.id}`,
          method: 'patch',
          headers: {
            Accept: 'application/json',
          },
          data: {
            product_id: state.productId,
            notes: null,
            quantity: state.quantity,
          },
        },
      };
    case 'SET_REQUEST_CONFIG':
      return {
        ...state,
        requestConfig: action.payload,
      };
    default:
      return state;
  }
};

export { basketNoteReducer };
