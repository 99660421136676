import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from '@cityelectricalfactors/ui-components';
import GlobalModalContent from 'components/shared/GlobalModalContent';

import { blackBlue85 } from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

/**
 * GlobalModal component for displaying a modal with dynamic content and actions.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.modalIsOpen - Indicates whether the modal is open.
 * @param {function} props.closeModal - Function to close the modal.
 * @param {function} props.successCallback - Function to execute on successful action.
 * @param {string} props.title - The title of the modal.
 * @param {string} props.content - The content of the modal.
 * @param {string} props.buttonMessage - The message to display on the action button.
 * @param {string} [props.successMessage] - Optional success message to display.
 * @returns {React.Element} The GlobalModal component.
 */
const GlobalModal = ({
  modalIsOpen,
  closeModal,
  successCallback,
  title,
  content,
  buttonMessage,
  successMessage,
}) => (
  <StyledModal isOpen={modalIsOpen} closeModal={closeModal}>
    <GlobalModalContent
      closeModal={closeModal}
      title={title}
      content={successMessage || content}
    />

    <ModalFooter>
      <ActionButtonContainer>
        <ActionButton
          className="pap-btn--med btn-black-blue br-4"
          type="button"
          aria-label={buttonMessage}
          handleClick={() =>
            successMessage ? closeModal() : successCallback()
          }
          label={buttonMessage}>
          {successMessage ? 'Close' : buttonMessage}
        </ActionButton>
      </ActionButtonContainer>
    </ModalFooter>
  </StyledModal>
);

/**
 * StyledModal component using styled-components.
 */
const StyledModal = styled(Modal)`
  > div {
    border-radius: 8px;
    padding: 0;
    width: 90%;
    max-width: 380px;
    > h2 {
      color: ${blackBlue85};
    }
  }
`;

/**
 * ModalFooter component using styled-components for styling the modal footer.
 */
const ModalFooter = styled.div`
  padding: 1.5rem;
`;

/**
 * ActionButtonContainer component using styled-components for styling the button container.
 */
const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 768px) {
    justify-content: end;
  }
`;

/**
 * ActionButton component using styled-components for styling the action button.
 */
const ActionButton = styled(Button)`
  font-weight: 500;
  border: 0;
  padding: 0;
  width: 48%;
  height: 44px;
  @media only screen and (min-width: 768px) {
    width: 25%;
  }
`;

export default GlobalModal;
