import React, { useReducer, useEffect, useRef, useCallback } from 'react';
import NoResults from 'components/admin/search/NoResults';
import SearchResultPromotion from 'components/admin/search/SearchResult';
import SelectedProduct from 'components/admin/search/ProductDetail';
import { useHTTP } from 'hooks/useRequest';
import { reducer, initialState } from 'reducers/adminOfferSearchReducer';

const AdminOfferSearch = ({ type }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    requestConfig,
    searchTerm,
    searchClassState,
    products,
    selectedProduct,
  } = state;
  const searchInputRef = useRef(null);

  const { data } = useHTTP(requestConfig);

  const resetState = () => {
    dispatch({ type: 'RESET_STATE' });
  };

  useEffect(() => {
    if (data?.length) {
      dispatch({
        type: 'SET_STATE',
        payload: {
          products: data,
          searchClassState: 'admin-product-search animated',
          results: true,
        },
      });
    } else {
      resetState();
      dispatch({
        type: 'SET_STATE',
        payload: {
          searchClassState: 'admin-product-search animated',
        },
      });
    }
  }, [data]);

  const config = {
    searchUrl:
      type === 'limited'
        ? '/admin/limited_time_promotions/search?q='
        : '/admin/general_offer_promotions/search?q=',
    productDom:
      type === 'limited'
        ? '#limited_time_product_promotion_product_id'
        : '#general_offer_product_promotion_product_id',
  };

  const handleClickOutside = useCallback(e => {
    if (searchInputRef.current && !searchInputRef.current.contains(e.target)) {
      resetState();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    const handleKeyDown = e => {
      if (e.key === 'Escape') {
        resetState();
      }
    };

    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('click', handleClickOutside);
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClickOutside]);

  const selectProduct = product => {
    dispatch({
      type: 'SET_STATE',
      payload: { products: [], selectedProduct: product },
    });
    searchInputRef.current.value = '';
    document.querySelector(config.productDom).value = product.id;
    document
      .querySelector('#limited_time_product_promotion_start_time')
      .focus();
  };

  const searchProducts = useCallback(() => {
    dispatch({ type: 'SET_STATE', payload: { selectedProduct: null } });
    if (searchTerm.length >= 3) {
      dispatch({
        type: 'SET_STATE',
        payload: {
          requestConfig: {
            url: `${config.searchUrl}${searchTerm}`,
            method: 'get',
            headers: {
              Accept: 'application/json',
            },
          },
        },
      });
    } else {
      resetState();
    }
  }, [searchTerm, config.searchUrl]);

  useEffect(() => {
    const timer = searchTerm ? setTimeout(() => searchProducts(), 400) : null;
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [searchTerm, searchProducts]);

  return (
    <div>
      <div className={searchClassState}>
        <div className="form-group">
          <input
            placeholder="Search Products by Catalogue or Description"
            type="text"
            ref={searchInputRef}
            className="search-input lh-1-5 account__input-wrapper 
            width-100 b-full b-colour--grey50 br-4 mb-16"
            onChange={e =>
              dispatch({
                type: 'SET_STATE',
                payload: { searchTerm: e.target.value, results: false },
              })
            }
            onClick={searchProducts}
          />
        </div>
        <div className="search-results-container">
          <table className="table table-hover search-results">
            <tbody>
              {products?.map(r => (
                <SearchResultPromotion
                  selectProduct={selectProduct}
                  key={r.id}
                  productData={r}
                />
              ))}
              {!products.length &&
                searchTerm.length >= 3 &&
                !selectedProduct && <NoResults key={1} />}
            </tbody>
          </table>
        </div>
      </div>
      <div className="selected-product-container">
        {selectedProduct && (
          <SelectedProduct product={selectedProduct} key={selectedProduct.id} />
        )}
      </div>
    </div>
  );
};

export default AdminOfferSearch;
