import { useAsync } from 'react-async-hook';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useState } from 'react';
import axios from 'packs/axios';
/**
 * Pricing is a async request which is called via setConfig() with the params
 * below.
 * @param {*} { url, method, params }
 * @return {
 *    success: true,
 *    data: itemPrice,
 * } or {
 *    success: false,
 *    data: {
 *      error:
 *       'Please refresh the page or contact your account manager for help.',
 *      error_title: 'Product prices are currently unavailable',
 *    },
 *  }
 */
const Pricing = async ({ url, method, params }) => {
  /**
   *  getPricing is the axios call to return the data from the service
   * @type {*} */

  const getPricing = await axios(url, {
    method,
    params,
  })
    .then(({ data: itemPrice }) => itemPrice)
    .then(itemPrice => {
      if (itemPrice.error) {
        return {
          success: false,
          data: {
            error:
              'Please refresh the page or contact your account manager for help.',
            error_title: 'Product prices are currently unavailable',
          },
        };
      }
      return {
        success: true,
        data: itemPrice,
      };
    })
    .catch(() => ({
      success: false,
      data: {
        error:
          'Please refresh the page or contact your account manager for help.',
        error_title: 'Product prices are currently unavailable',
      },
    }));

  return getPricing;
};
/**
 * usePricing is the custom Hook referenced from a component.
 * This returns the response from the service and also provides reference to
 * the setConfig function to hit the service.
 *
 * @return {
 *   response,
 *   setConfig,
 * }
 */
const usePricing = () => {
  const [config, setConfig] = useState(null);

  const debouncePricing = useConstant(() =>
    AwesomeDebouncePromise(Pricing, 300),
  );

  const response = useAsync(async () => debouncePricing(config), [config]);
  return {
    response,
    setConfig,
  };
};

export default usePricing;
