import React from 'react';
import styled from 'styled-components';

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { Arrow } from 'components/shared/CommonSvgs';

/**
 * @param {Object} props
 * @param {Object} props.media - media assets to populate the main product carousel
 * @returns the product image carousel
 */

const ProductImageCarousel = ({
  media: { images, zoom, thumbs, large_thumbs: largeThumbs },
}) => {
  const handlePaginationMount = (_instance, data) => {
    data.list.classList.add(
      'splide__pagination--thumbnails',
      'product_thumbnails',
      'col-12',
      'm-col-2',
      'fl',
      'm-fl-col',
    );

    // `items` contains all dot items
    // create product image thumbnails
    for (let i = 0; i < data.items.length; i++) {
      if (i === thumbs.length) {
        break;
      }

      const img = document.createElement('img');

      img.src = largeThumbs[i];

      img.alt = '';

      // set first thumbnail to active state
      if (i === 0) {
        data.items[i].button.classList.add('is-active');
      }
      // add to DOM
      data.items[i].button.appendChild(img);
    }
  };

  if (!images.length) return null;

  if (images.length === 1) {
    return (
      <div className="splide__slide--0 product-details__main-image col-12 m-col-10">
        <StyledSlideSingular
          aria-label="Single Product image carousel"
          label="click to zoom"
          className="splide__slide--singular">
          <picture>
            <source
              srcSet={images[0].replace('.jpg', '.webp')}
              type="image/webp"
            />
            <img
              src={images[0]}
              alt=""
              width="287"
              height="287"
              className="product-carousel__image"
            />
          </picture>
        </StyledSlideSingular>
      </div>
    );
  }

  return (
    <Splide
      className="product-details__images row col-12 m-col-7 m-mr-16 mb-24"
      hasTrack={false}
      aria-label="Product image carousel"
      onPaginationMounted={handlePaginationMount}>
      <div className="splide__arrows">
        <button
          type="button"
          aria-label="Previous slide"
          className="splide__arrow splide__arrow--prev br-100">
          <Arrow />
        </button>
        <button
          type="button"
          aria-label="Next slide"
          className="splide__arrow splide__arrow--next br-100">
          <Arrow />
        </button>
      </div>

      <SplideTrack className="product-details__main-image col-12 m-col-10">
        {images.map((img, i) => (
          <StyledSlide
            className={`splide__slide splide__slide--${i} product-details__main-image 
            jc-c ai-fs`}
            key={img}>
            <picture>
              <source srcSet={img.replace('.jpg', '.webp')} type="image/webp" />
              <img
                src={img}
                alt=""
                width="497"
                height="497"
                srcSet={`${img}, ${zoom[i]} 2x`}
                className="product-carousel__image"
              />
            </picture>
          </StyledSlide>
        ))}
      </SplideTrack>
    </Splide>
  );
};

const StyledSlide = styled(SplideSlide)`
  display: flex;

  button {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 80%;
    margin: 0 auto;
  }

  img {
    width: 100%;
  }
`;

const StyledSlideSingular = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 100%;
`;

export default ProductImageCarousel;
