import React, { useReducer, useEffect, useRef } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useHTTP } from 'hooks/useRequest';
import { AutocompleteIcon } from 'components/shared/CommonSvgs';
import { initialState, reducer } from 'reducers/checkoutAddressSearchReducer';
import {
  blackBlue85,
  white,
  grey10,
  grey50,
} from '../../../../../assets/stylesheets/v2/abstracts/_variables.scss';
import SearchResult from './SearchResult';
import AddressLoadingState from './LoadingState';
import AddressLookupError from './AddressLookupError';
import InvalidAddress from './InvalidAddress';
import SearchResultsContainer from './SearchResultsContainer';

const CheckoutAddressSearch = ({
  setConfirmDisabled,
  setDeliveryAddressIsInvoiceAddress,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    invalidAddress,
    requestConfig,
    searching,
    postcodeLookupError,
    valid,
    addressesFound,
  } = state;
  const searchTermRef = useRef(null);

  const { error, data } = useHTTP(requestConfig);

  useEffect(() => {
    if (error) {
      const {
        error: {
          data: { message },
        },
      } = error;
      if (message === 'Invalid Postcode') {
        dispatch({ type: 'SET_INVALID_ADDRESS', payload: true });
      }
      dispatch({ type: 'SET_ERROR_STATE' });
    }
  }, [error]);

  useEffect(() => {
    if (data?.length > 0) {
      dispatch({ type: 'SET_DATA_STATE', payload: data });
    }
  }, [data]);

  const checkValid = () => {
    dispatch({
      type: 'SET_VALID',
      payload: searchTermRef.current.value !== '',
    });
    dispatch({ type: 'SET_POSTCODE_LOOKUP_ERROR', payload: false });
    dispatch({ type: 'SET_INVALID_ADDRESS', payload: false });
    setConfirmDisabled(false);
  };

  const resetInvalidAddress = () => {
    dispatch({ type: 'SET_INVALID_ADDRESS', payload: false });
    searchTermRef.current.value = '';
    searchTermRef.current.focus();
  };

  const handleSearch = () => {
    dispatch({
      type: 'SET_REQUEST_CONFIG',
      payload: {
        url: `/checkout/address_lookup?q=${searchTermRef.current.value}`,
        method: 'get',
        headers: {
          Accept: 'application/json',
        },
      },
    });
  };

  return (
    <div className="postcode-lookup-container form-group">
      {invalidAddress && (
        <InvalidAddress resetInvalidAddress={resetInvalidAddress} />
      )}
      {postcodeLookupError && !invalidAddress && <AddressLookupError />}
      {searching && !postcodeLookupError && <AddressLoadingState />}

      <div className="search-form">
        <div className="input-group search-group row">
          <input
            className="form-control search-group-control plr-16 col-12 m-col-6 fs-16"
            placeholder="Postcode"
            ref={searchTermRef}
            onChange={checkValid}
            type="text"
          />

          <SearchButton
            type="button"
            className="btn-black-blue--bordered pap-btn--sm br-4 fl ai-c
            jc-c fs-14 p-14 col-12 m-col-4"
            handleClick={() => valid && handleSearch()}>
            <AutocompleteIcon className="mr-5" width="18" height="18" />
            Search
          </SearchButton>
        </div>
      </div>

      {addressesFound.length > 0 && (
        <SearchResultsContainer
          addressesFound={addressesFound.length}
          reset={() => dispatch({ type: 'RESET' })}>
          {addressesFound.map((address, index) => {
            const uniqueId = uuidv4();
            return (
              <SearchResult
                setDeliveryAddressIsInvoiceAddress={
                  setDeliveryAddressIsInvoiceAddress
                }
                key={`address-${uniqueId}`}
                address={address}
                id={index}
              />
            );
          })}
        </SearchResultsContainer>
      )}
    </div>
  );
};

const SearchButton = styled(Button)`
  border: 2px solid ${blackBlue85};
  color: ${blackBlue85};
  background-color: ${white};

  &:disabled {
    border-color: ${grey50};
    color: ${grey50};
    background-color: ${grey10};
  }

  svg {
    fill: ${blackBlue85};
  }

  &:hover {
    svg {
      fill: ${white};
    }
  }
`;

export default CheckoutAddressSearch;
