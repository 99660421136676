import React from 'react';
import { Splide } from '@splidejs/react-splide';

import { Arrow } from './CommonSvgs';

const ProductSlider = ({
  children,
  showArrows = true,
  numberOfItems,
  options,
}) => (
  <div className="product-slider">
    <Splide
      options={options}
      hasTrack={false}
      className="splide"
      role="group"
      aria-label="product slider">
      {numberOfItems > options.perPage && showArrows && (
        <div className="splide__arrows">
          <button
            type="button"
            aria-label="Previous slide"
            className="splide__arrow splide__arrow--prev br-100">
            <Arrow />
          </button>
          <button
            type="button"
            aria-label="Next slide"
            className="splide__arrow splide__arrow--next br-100">
            <Arrow />
          </button>
        </div>
      )}
      <div className="splide__track mb-16">{children}</div>
    </Splide>
  </div>
);

export default ProductSlider;
