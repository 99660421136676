// toastStore.js
import { create } from 'zustand';

const useToastStore = create(set => ({
  toasts: [],
  addToast: (message, description, type = 'info') => {
    const id = Date.now();
    set(state => ({
      toasts: [...state.toasts, { id, message, description, type }],
    }));

    setTimeout(() => {
      set(state => ({
        toasts: state.toasts.filter(toast => toast.id !== id),
      }));
    }, 5000); // remove after 5 seconds
  },
  removeToast: id => {
    set(state => ({
      toasts: state.toasts.filter(toast => toast.id !== id),
    }));
  },
}));

export default useToastStore;
