import React from 'react';

const ProductsPerPage = ({ perPage, perPageOptions, setPerPage }) => (
  <div className="products__per-page jc-fe p-10">
    <div className="float-label__wrapper pos-rel fl b-full b-colour--grey br-4 p-10 pl-6">
      <select
        className="float-label__input bg-none width-100 br-4 ff--body mt-10"
        onChange={e => {
          setPerPage(parseInt(e.target.selectedOptions[0]?.value, 10));
        }}
        value={perPage}>
        {perPageOptions.map(option => (
          <option key={option}>{option}</option>
        ))}
      </select>
      <span className="pos-absolute c--grey50 fs-14">Showing</span>
    </div>
  </div>
);

export default ProductsPerPage;
