/**
 * Converts a pixel value to rem units.
 * @param {number} px - The pixel value to convert.
 * @param {number} [base=16] - The base font size to use for conversion. Default is 16px.
 * @returns {string} The equivalent value in rem units.
 */
const toRem = (px, base = 16) => {
  return `${px / base}rem`;
};

export default toRem;
