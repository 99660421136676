import React from 'react';

const SearchResultsContainer = ({ addressesFound, children }) => (
  <>
    <h3>
      Found <span className="count">{addressesFound}</span> Addresses
    </h3>
    <p className="fs-14">
      Select the address you would like your order to be delivered to and this
      address will be added to your address book.
    </p>
    <div className="postcode-lookup__scrollable-results row">{children}</div>
  </>
);

export default SearchResultsContainer;
