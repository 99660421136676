import React from 'react';
import { InStockIcon } from 'components/shared/CommonSvgs';
import { successGreen } from '../../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const AddressLoadingState = () => (
  <div className="fl mtb-12 p-6 fs-14 b-full b-colour-green bgc--green br-2">
    <InStockIcon
      className="mr-4"
      width="20"
      height="20"
      fillColor={successGreen}
    />
    <p className="fl fl-col nm np">
      <span className="fw-700 fs-16 mr-4">Searching for Addresses</span>
    </p>
  </div>
);

export default AddressLoadingState;
