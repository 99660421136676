import React from 'react';
import { WhiteCheck } from 'components/shared/CommonSvgs';
import { successGreen } from '../../../assets/stylesheets/v2/abstracts/_variables.scss';

const BasketLineMessage = ({ message }) => (
  <div className="basket-line-message fl ai-c jc-fe p-l24 pr-24 pt-10 pb-10">
    <WhiteCheck
      fillColor={successGreen}
      width="15"
      height="15"
      viewBox="0 0 10 7"
      className="mr-8"
    />
    <p className="fl ai-c m-0 fs-12 ">{message}</p>
  </div>
);

export default BasketLineMessage;
