import React, { useState, useRef, useEffect } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from 'components/shared/CommonSvgs';

const AccountSidebarMobile = ({ links, currentPageName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState({});
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const matchFound = links.find(link => link.text === currentPageName);

    if (matchFound) {
      setCurrentPage(matchFound);
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="account-sidebar__mobile" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="account-sidebar__mobile-menu-trigger fl ai-c jc-sb fs-16 plr-14"
        type="button">
        <div className="fl ai-c td-none c--black-blue">
          <div className="fl ai-c mr-5">{currentPage.icon}</div>
          {currentPage.text}
        </div>
        {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>

      <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
        <ul className="ls-none m-0 p-0 width-100">
          {links.map(({ url, icon, text }, index, arr) => (
            <li
              className="dropdown-item"
              key={`account-sidebar__mobile-menu-${text}`}>
              <a
                href={url}
                className={`account-sidebar__link fl ai-c p-10 bt b-colour--grey td-none 
                c--black-blue ${arr.length - 1 === index ? 'bb' : ''}`}>
                <div className="fl ai-c mr-5">{icon}</div>
                {text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AccountSidebarMobile;
