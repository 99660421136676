import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@cityelectricalfactors/ui-components';
import ProductAggregateOption from './aggregate_option';
import getClosest from './getClosest';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  AutocompleteIcon,
  AutocompleteCloseIcon,
} from '../../../shared/CommonSvgs';
import {
  grey5,
  grey70,
} from '../../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const ProductAggregate = ({
  collapseAllFilters,
  agg,
  toggleAggregate,
  showCheckbox,
}) => {
  const { options: aggOptions, title } = agg;
  const [expanded, setExpanded] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [originalOptions, setOriginalOptions] = useState(aggOptions);
  const [filteredOptions, setFilteredOptions] = useState(aggOptions);
  // equiv - componentDidMount
  useEffect(() => {
    setExpanded(!collapseAllFilters ? true : false);
  }, []);
  // equiv - componentWillReceiveProps
  useEffect(() => {
    setOriginalOptions(aggOptions);
    setFilteredOptions(aggOptions);
    // filterOptions();
  }, [agg]);

  // update filtered options
  useEffect(() => {
    let newFilteredOptions = originalOptions.filter(option => {
      if (option.key === null) return;

      return option.key.toUpperCase().includes(filterValue.toUpperCase()) ===
        true
        ? option
        : false;
    });

    setFilteredOptions(newFilteredOptions);
  }, [filterValue]);

  const toggleAggregation = e => {
    setExpanded(!expanded);

    const aggTitle = getClosest(e.target, '.aggregation__title');
    aggTitle.classList.toggle('aggregation__title--closed');
    const aggOptions = aggTitle.nextSibling;
    aggOptions.classList.toggle('aggregation__options--closed');
    const viewAllToggle = aggOptions.nextSibling;
    if (viewAllToggle?.nodeName === 'DIV') {
      viewAllToggle.classList.toggle('aggregation__view-all--closed');
    }
  };

  const toggleViewAll = e => {
    setViewAll(!viewAll);

    const aggOptions = e.target.previousSibling;
    aggOptions.classList.toggle('aggregation__options--show-all');
    aggOptions.scrollTop = 0;
  };

  const handleFilterValue = e => {
    setFilterValue(e.target.value);
  };

  const resetSearch = () => {
    setFilterValue('');
  };

  const toggled = expanded === false ? <ArrowDownIcon /> : <ArrowUpIcon />;

  const viewAllText = viewAll
    ? 'Show less filters'
    : `Show all filters (${filteredOptions.length})`;

  const options =
    filteredOptions.length === 0 && aggOptions.length === 0 ? (
      <strong>No Options found.</strong>
    ) : (
      filteredOptions.map(option => (
        <ProductAggregateOption
          key={option.value}
          option={option}
          agg_type={title}
          toggleAggregate={toggleAggregate}
          showCheckbox={showCheckbox}
        />
      ))
    );

  return (
    <>
      {aggOptions.length >= 1 ? (
        <>
          <Aggregation className="product-filter__aggregation np nm ls-none ">
            <li
              className="aggregation__title fl jc-sb ai-c"
              onClick={e => toggleAggregation(e)}>
              <span>{title.replace('_', ' ')}</span>
              {toggled}
            </li>
            <ul
              className={
                collapseAllFilters && aggOptions.length > 11
                  ? 'no-bullet aggregation__options aggregation__options--closed aggregation__options--view-all'
                  : collapseAllFilters
                    ? 'no-bullet aggregation__options aggregation__options--closed'
                    : aggOptions.length > 11 && filteredOptions.length > 11
                      ? 'no-bullet aggregation__options aggregation__options--view-all np nm ls-none'
                      : 'no-bullet aggregation__options np nm ls-none'
              }>
              {aggOptions.length > 11 && (
                <div className="aggregate__search fl ai-c mtb-12 p-10 b-full br-4">
                  {!filterValue && (
                    <AutocompleteIcon
                      width="20"
                      height="20"
                      fillColor={grey70}
                    />
                  )}
                  <input
                    className="aggregation-search__input width-100 b-none bg-none"
                    type="text"
                    value={filterValue}
                    placeholder="Search"
                    onChange={e => handleFilterValue(e)}
                  />
                  {filterValue && (
                    <>
                      <button
                        type="button"
                        label="Clear"
                        aria-label="Clear"
                        className="fl ai-c bg-none b-none"
                        onClick={() => resetSearch()}>
                        <AutocompleteCloseIcon
                          width="14"
                          height="14"
                          fillColor={grey70}
                        />
                      </button>
                    </>
                  )}
                </div>
              )}
              {options}
            </ul>
            {expanded &&
            aggOptions.length > 11 &&
            filteredOptions.length > 11 ? (
              <Button
                className="aggregation__view-all p-6 ptb-22 fs-14 td-u c--blue bg-none"
                handleClick={e => toggleViewAll(e)}>
                {viewAllText}
              </Button>
            ) : (
              ''
            )}
          </Aggregation>
        </>
      ) : (
        ''
      )}
    </>
  );
};

const Aggregation = styled.ul`
  margin-left: 0;
  padding-left: 0;

  .aggregation__options {
    .aggregate {
      color: #555555;
      padding: 12px 0;

      &:hover {
        cursor: pointer;
        background-color: ${grey5};
      }
    }

    button :hover {
      cursor: pointer;
    }
  }

  .aggregation__options--closed,
  .aggregation__view-all--closed {
    display: none;
  }

  .aggregation__options--view-all {
    border-bottom: 0;
    margin-bottom: 0;
    max-height: 200px;
    overflow: hidden;
  }

  .aggregation__options--show-all {
    max-height: 615px;
    overflow: scroll;
  }
`;

export default ProductAggregate;
