import { create } from 'zustand';

const userCompareStore = (set, get) => ({
  userCompareProducts: [],
  isDuplicate: false,
  addCurrentCompareProducts: currentProducts => {
    set(() => ({
      userCompareProducts: currentProducts,
    }));
  },
  addCompareProduct: product => {
    const products = get().userCompareProducts;
    const productExists = products.some(item => item.id === product.id);
    let updatedProducts;
    if (productExists) {
      updatedProducts = products.filter(p => p.id !== product.id);
    }

    set(() => ({
      isDuplicate: productExists,
      userCompareProducts: productExists
        ? updatedProducts
        : [...products, product],
    }));
  },
  removeCompareProduct: id => {
    const products = get().userCompareProducts;
    const updatedProducts = products.filter(product => product.id !== id);

    set(() => ({
      userCompareProducts: updatedProducts,
    }));
  },
});

const useUserCompareStore = create(userCompareStore);

export default useUserCompareStore;
